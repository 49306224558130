import axios from "axios";

export const LOGIN_CAMPUS_SUCCESS_ACTION = "LOGIN_CAMPUS_SUCCESS_ACTION";
export const LOGIN_FAMILY_SUCCESS_ACTION = "LOGIN_FAMILY_SUCCESS_ACTION";
export const LOGIN_FAILURE_ACTION = "LOGIN_FAILURE_ACTION";
export const LOGOUT_NOW_ACTION = "LOGOUT_NOW_ACTION";

//Action creators
export const loginCampusSuccessAction = () =>{
    return({
        type: LOGIN_CAMPUS_SUCCESS_ACTION,
    })
}

export const loginFamilySuccessAction = () =>{
    return({
        type: LOGIN_FAMILY_SUCCESS_ACTION,
    })
}

export const loginFailureAction = (message)=>{
    return({
        type: LOGIN_FAILURE_ACTION,
        message: message
    })
}

export const logoutNowAction = () =>{
    return({
        type: LOGOUT_NOW_ACTION,
    })
}



//Thunk action
//=============
export const signUpCampusThunk = (email, password)=>{
    return async(dispatch)=>{
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/auth/campus/signup`,{
                email: email,
                password: password,
            })
            //if successful, data = {token: token}
            //wrong pw, data = {message: 'Wrong pw'}
            if(data == null) {
                dispatch(loginFailureAction('Unknown error'))
            } else if(!data.token) {
                dispatch(loginFailureAction(data.message || 'No token'))
            } else {
                console.log('successful campus sign up')
                dispatch(loginCampusSuccessAction())
            }
        } catch (err) {
            console.log(err);
        } 
    }
}

export const loginCampusThunk = (email, password) => {
    return async (dispatch) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/auth/campus/login`,{
                email: email,
                password: password,
            })
            //if successful, data = {token: token}
            //wrong pw, data = {message: 'Wrong pw'}
            if(data == null) {
                dispatch(loginFailureAction('Unknown error'))
            } else if(!data.token) {
                dispatch(loginFailureAction(data.message || 'No token'))
            } else {
                localStorage.setItem("token", data.token);
                localStorage.setItem("name", data.name);
                localStorage.setItem("role", 'campus');
                console.log('success campus login')
                dispatch(loginCampusSuccessAction())
            }

        } catch (err) {
            console.log(err);
        } 
  }};

export const signUpFamilyThunk = (email, password)=>{
    return async(dispatch)=>{
        try{
            const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/auth/family/signup`,{
                email: email,
                password: password,
            })
            //if successful, data = {token: token}
            //wrong pw, data = {message: 'Wrong pw'}
            if(data == null) {
                dispatch(loginFailureAction('Unknown error'))
            } else if(!data.token) {
                dispatch(loginFailureAction(data.message || 'No token'))
            } else {
                console.log('successful family sign up')
                dispatch(loginFamilySuccessAction())
            }
        } catch (err) {
            console.log(err);
        } 
    }
}

export const loginFamilyThunk = (email, password) => {
    return async (dispatch) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/auth/family/login`,{
                email: email,
                password: password,
            })
            //if successful, data = {token: token}
            //wrong pw, data = {message: 'Wrong pw'}
            if(data == null) {
                dispatch(loginFailureAction('Unknown error'))
            } else if(!data.token) {
                dispatch(loginFailureAction(data.message || 'No token'))
            } else {
                localStorage.setItem("token", data.token);
                localStorage.setItem("name", data.name);
                localStorage.setItem("role", 'family');
                console.log('success family login')
                dispatch(loginFamilySuccessAction())
            }
        } catch (err) {
            console.log(err);
        } 
  }};

export const logoutNowThunk = () => {
    return (dispatch) => {
        localStorage.clear("token");
        localStorage.setItem("role", '');
        dispatch(logoutNowAction());
    };
}

export const loginGoogleThunk = (info) =>{
    return async(dispatch) => {
        const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/auth/family/login/google`, {
            info: info,
        })
        console.log(response.data)

        if (response.data == null) {
            dispatch(loginFailureAction("Unknown Error"));
        } else if (!response.data.jwtToken) {
            dispatch(loginFailureAction(response.data.message || "No token id"));
        } else {
            localStorage.setItem("token", response.data.jwtToken);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("role", 'family');
            console.log('success Google login')
            dispatch(loginFamilySuccessAction())
        }
    }
};

