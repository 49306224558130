import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { announcementReducers } from "./announcement/reducers";
import { authReducers } from "./auth/reducers";
import { courseReducers } from "./course/reducers";
import { infoReducers } from "./info/reducers";
import { lessonReducers } from "./lesson/reducers";
import { reviewReducers } from "./review/reducers";
import { listingReducers } from "./listing/reducers";
import { scoreReducers } from "./score/reducers";
import { loadingReducers } from "./loading/reducers";
import { loading2Reducers } from "./loading2/reducers";
import { locationReducers } from "./location/reducers";
import { imageReducers } from "./imageS3/reducers";
import { familyInfoReducers } from "./familyInfo/reducers";
import { addInfoReducers } from "./addInfo/reducers";
import { courseInfoReducers } from "./courseInfo/reducers";
import { attendanceReducers } from "./attendance/reducers";
import logger from "redux-logger";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  announcementStore: announcementReducers,
  authStore: authReducers,
  courseStore: courseReducers,
  infoStore: infoReducers,
  lessonStore: lessonReducers,
  reviewStore: reviewReducers,
  listingStore: listingReducers,
  scoreStore: scoreReducers,
  loadingStore: loadingReducers,
  loading2Store: loading2Reducers,
  locationStore: locationReducers,
  imageStore: imageReducers,
  familyInfoStore: familyInfoReducers,
  addInfoStore: addInfoReducers,
  courseInfoStore: courseInfoReducers,
  attendanceStore: attendanceReducers,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);
