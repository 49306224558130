import axios from "axios";
import { loadingToggleAction } from "../loading/actions";
export const REVIEW_SUCCESS_ACTION = "REVIEW_SUCCESS_ACTION";
export const REVIEW_FAILURE_ACTION = "REVIEW_FAILURE_ACTION";

//Thunk action creator
export const reviewSuccess = (review) => {
  return {
    type: REVIEW_SUCCESS_ACTION,
    review: review,
  };
};

export const reviewFailure = (message) => {
  return {
    type: REVIEW_FAILURE_ACTION,
    message: message,
  };
};

//Thunk actions

//Get Latest reviews
export const reviewGetLatestThunk = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/review/latest`)
      .then((res) => {
        console.log(res.data);
        dispatch(reviewSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(reviewFailure(`post request error: ${err}`));
      });
  };
};

//Get Request
export const reviewGetAllThunk = (campusId) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/review/all/${campusId}`)
      .then((res) => {
        console.log(res.data);
        dispatch(reviewSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(reviewFailure(`post request error: ${err}`));
      });
  };
};

//Review Post Request
export const reviewPostThunk = (campusId, review) => {
  return (dispatch) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_API_SERVER}/api/review/${campusId}`,
        { review: review },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        dispatch(reviewSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(reviewFailure(`post request error: ${err}`));
      });
  };
};
