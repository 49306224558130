import axios from "axios";
export const COURSEINFO_GET_REQUEST = "COURSEINFO_GET_REQUEST";
export const COURSEINFO_GET_SUCCESS = "COURSEINFO_GET_SUCCESS";
export const COURSEINFO_GET_FAILURE = "COURSEINFO_GET_FAILURE";

//Thunk action creators
//Get all course thunk action creators
export const courseGetRequest = () => {
  return {
    type: COURSEINFO_GET_REQUEST,
  };
};
export const courseGetSuccess = (data) => {
  return {
    type: COURSEINFO_GET_SUCCESS,
    course: data.course,
  };
};

export const courseGetFailure = (message) => {
  return {
    type: COURSEINFO_GET_FAILURE,
    message: message,
  };
};

//Get all course thunk actions
export const courseGetSuccessThunk = () => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    const data = {};
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/campus`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const campus_id = res.data[0].id;
        axios
          .get(
            `${process.env.REACT_APP_API_SERVER}/api/course/campus/${campus_id}`
          )
          .then((res) => {
            data.course = res.data;
            for (let i = 0; i < data.course.length; i++) {
              const enrolment = {};
              const lesson = {};
              axios
                .get(
                  `${process.env.REACT_APP_API_SERVER}/api/enrolment/course/courseId/${data.course[i].id}`
                )
                .then((res) => {
                  if (!res.data || res.data.length === 0) {
                    enrolment[0] = [];
                  } else {
                    enrolment[0] = res.data;
                  }
                })
                .then(() => {
                  axios
                    .get(
                      `${process.env.REACT_APP_API_SERVER}/api/lesson/course/courseId/${data.course[i].id}`
                    )
                    .then((res) => {
                      if (!res.data || res.data.length === 0) {
                        lesson[0] = [];
                      } else {
                        lesson[0] = res.data;
                        for (let j = 0; j < res.data.length; j++) {
                          const attendance = {};
                          axios
                            .get(
                              `${process.env.REACT_APP_API_SERVER}/api/attendance/lesson/${res.data[j].id}`
                            )
                            .then((res) => {
                              if (!res.data || res.data.length === 0) {
                                attendance[0] = [];
                              } else {
                                attendance[0] = res.data;
                              }
                            });
                          lesson[0][j].attendance = attendance;
                        }
                      }
                    });
                });
              data.course[i].enrolment = enrolment;
              data.course[i].lesson = lesson;
              console.log(data, "ALLDATA!!!");
            }
            dispatch(courseGetSuccess(data));
          });
      })
      // .then(() => dispatch(scoreGetSuccess(data)))
      .catch((err) => {
        dispatch(courseGetFailure(`post request error: ${err}`));
      });
  };
};
