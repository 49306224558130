import { LISTING_GET_SUCCESS, LISTING_GET_FAILURE } from "./actions";

const initialState = {
  listing: [],
};

export function listingReducers(state = initialState, action) {
  switch (action.type) {
    case LISTING_GET_SUCCESS:
      return { listing: action.listings, review: action.reviews };

    case LISTING_GET_FAILURE:
      return state;
    default:
      return state;
  }
}
