import axios from "axios";
import { loadingToggleAction } from "../loading/actions";
export const LISTING_GET_REQUEST = "USER_GET_REQUEST";
export const LISTING_GET_SUCCESS = "USER_GET_SUCCESS";
export const LISTING_GET_FAILURE = "USER_GET_FAILURE";

//Thunk action creators
//Get all listing thunk action creators
export const listingGetRequest = () => {
  return {
    type: LISTING_GET_REQUEST,
  };
};
export const listingGetSuccess = (data) => {
  return {
    type: LISTING_GET_SUCCESS,
    listings: data.listings,
    reviews: data.reviews,
  };
};
export const listingGetFailure = (message) => {
  return {
    type: LISTING_GET_FAILURE,
    message: message,
  };
};

//Get all listing and review thunk actions
export const listingGetThunk = () => {
  return (dispatch) => {
    const data = {};
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/allCampus`)
      .then((res) => {
        data.listings = res.data;
        const reviews = {};
        for (let i = 0; i < res.data.length; i++) {
          axios
            .get(
              `${process.env.REACT_APP_API_SERVER}/api/review/all/${res.data[i].id}`
            )
            .then((res) => {
              reviews[`${res.data[i]?.id}`] = res.data;
            });
        }
        data.reviews = reviews;
      })
      .then(() => dispatch(listingGetSuccess(data)))
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 200)
      )
      .catch((err) => {
        dispatch(listingGetFailure(`post request error: ${err}`));
      });
  };
};

//Get sorted listing and review thunk actions
export const sortedListingGetThunk = (sorting) => {
  return (dispatch) => {
    // console.log(sorting, "sorting");
    const data = {};
    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/info/campus`, {
        sorting: sorting[0],
      })
      .then((res) => {
        data.listings = res.data;
        const reviews = {};
        for (let i = 0; i < res.data.length; i++) {
          axios
            .get(
              `${process.env.REACT_APP_API_SERVER}/api/review/all/${res.data[i].id}`
            )
            .then((res) => {
              reviews[`${res.data[i].id}`] = res.data;
            });
        }
        data.reviews = reviews;
      })
      .then(() => dispatch(listingGetSuccess(data)))
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 200)
      )
      .catch((err) => {
        dispatch(listingGetFailure(`post request error: ${err}`));
      });
  };
};

//Get advanced sorted listing and review thunk actions
export const advancedSortedListingGetThunk = (sorting, district_ids) => {
  return (dispatch) => {
    console.log(sorting, "sorting");
    console.log(district_ids, "district_ids");
    const data = {};
    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/info/campusAdvanced`, {
        sorting: sorting[0],
        district_ids: district_ids,
      })
      .then((res) => {
        data.listings = res.data;
        const reviews = {};
        for (let i = 0; i < res.data.length; i++) {
          axios
            .get(
              `${process.env.REACT_APP_API_SERVER}/api/review/all/${res.data[i].id}`
            )
            .then((res) => {
              reviews[`${res.data[i].id}`] = res.data;
            });
        }
        data.reviews = reviews;
      })
      .then(() => dispatch(listingGetSuccess(data)))
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        dispatch(listingGetFailure(`post request error: ${err}`));
      });
  };
};

//Thunk actions
//Get all listing info thunk actions
// export const listingGetThunk = () => {
//   return (dispatch) => {
//     axios
//       .get(`${process.env.REACT_APP_API_SERVER}/api/info/allCampus`)
//       .then((res) => {
//         dispatch(listingGetSuccess(res.data));
//       })
//       .catch((err) => {
//         dispatch(listingGetFailure(`error: ${err}`));
//       });
//   };
// };

//Get all listing review thunk actions
// export const reviewGetAllThunk = (campusId) => {
//   return (dispatch) => {
//     let token = localStorage.getItem("token");
//     axios
//       .get(`${process.env.REACT_APP_API_SERVER}/api/review/all/${campusId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((res) => {
//         console.log(res.data);
//         dispatch(reviewSuccess(res.data));
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch(reviewFailure(`post request error: ${err}`));
//       });
//   };
// };
