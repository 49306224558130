import axios from "axios";
import { infoSuccess } from "../info/actions";
import { courseGetSuccessThunk } from "../../Redux/courseInfo/actions";

export const IMAGE_SUCCESS_ACTION = "IMAGE_SUCCESS_ACTION";
export const IMAGE_FAILURE_ACTION = "IMAGE_FAILURE_ACTION";

//Thunk action creator
export const imageSuccess = (message) => {
  return {
    type: IMAGE_SUCCESS_ACTION,
    message: message,
  };
};

export const imageFailure = (message) => {
  return {
    type: IMAGE_FAILURE_ACTION,
    message: message,
  };
};

//Thunk actions

//Get Request (campus)
export const imageUploadThunk = (file, endpoint, item) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    if (!file) {
      dispatch(imageFailure(`Please select an image before uploading`));
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/imageupload`)
      .then((res) => {
        console.log(file);
        //Upload image to s3 bucket
        axios
          .put(res.data.url, file, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => console.log("uploaded"));
        console.log(res.data.url.split("?")[0]);
        let imageUrl = res.data.url.split("?")[0];

        // let info = {}
        // info[item] = imageUrl
        //Save image url to knex
        axios.put(
          `${process.env.REACT_APP_API_SERVER}${endpoint}`,
          {
            info: { [item]: imageUrl },
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        dispatch(imageSuccess(`Updated successfully`));
      })
      .catch((err) => {
        console.log(err);
        dispatch(imageFailure(`Error: ${err}`));
      });
  };
};

//Get Request (course)
export const imageUploadCourseThunk = (file, endpoint, item) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    if (!file || file == null) {
      dispatch(imageFailure(`Please select an image before uploading`));
      axios.put(
        `${process.env.REACT_APP_API_SERVER}${endpoint}`,
        {
          info: { [item]: "" },
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/imageupload`)
      .then((res) => {
        console.log(file);
        //Upload image to s3 bucket
        axios
          .put(res.data.url, file, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => console.log("uploaded"));
        console.log(res.data.url.split("?")[0]);
        let imageUrl = res.data.url.split("?")[0];

        // let info = {}
        // info[item] = imageUrl
        //Save image url to knex
        axios.put(
          `${process.env.REACT_APP_API_SERVER}${endpoint}`,
          {
            info: { [item]: imageUrl },
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        dispatch(imageSuccess(`Updated successfully`));
        dispatch(courseGetSuccessThunk()); // auth campus ID
      })
      .catch((err) => {
        console.log(err);
        dispatch(imageFailure(`Error: ${err}`));
      });
  };
};
