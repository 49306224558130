import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFamilyThunk } from "../../Redux/addInfo/actions";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { loadingToggleAction } from "../../Redux/loading/actions";
import { loading2ToggleAction } from "../../Redux/loading2/actions";
import * as ReactBootstrap from "react-bootstrap";

export default function FamilyAddNew() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(false));
    dispatch(loading2ToggleAction(false));
  }, []);

  const loadingStatus = useSelector((state) => state.loadingStore.showLoading);
  const loading2Status = useSelector(
    (state) => state.loading2Store.showLoading
  );

  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [parentFname, setParentFname] = useState("");
  const [parentLname, setParentLname] = useState("");
  const [relationship, setRelationship] = useState("");

  const [parentNum, setParentNum] = useState(1);
  const [childNum, setChildNum] = useState(1);

  const handleParentAdd = (e) => {
    e.preventDefault();
    setParentNum((prev) => prev + 1);
  };

  const handleParentDel = (e) => {
    e.preventDefault();
    setParentNum(parentNum - 1);
  };

  const renderParentDeleteBtn = (i) => {
    if (i >= 1) {
      return <button onClick={handleParentDel}>-</button>;
    }
  };

  const handleChildAdd = (e) => {
    e.preventDefault();
    setChildNum(childNum + 1);
  };

  const handleChildDel = (e) => {
    e.preventDefault();
    setChildNum(childNum - 1);
  };

  const renderChildDeleteBtn = (i) => {
    if (i >= 1) {
      return <button onClick={handleChildDel}>-</button>;
    }
  };

  const signup = (e) => {
    e.preventDefault();
    dispatch(loadingToggleAction(true));
    dispatch(
      addFamilyThunk(email, tel, parentFname, parentLname, relationship)
    );
  };

  return (
    <div class="div-block-4">
      <div class="w-form">
        {/* Add Family Form start */}
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          class="form-3"
        >
          <h4>Add New Family</h4>
          <label for="email">Email Address</label>
          <input
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
            type="email"
            className="w-input"
            maxLength="256"
            name="Email"
            data-name="Email"
            placeholder=""
            id="Email"
            required
          />
          <div class="form-wrapper">
            <h4>Primary parent account</h4>
          </div>
          <div class="account-card">
            <div class="form-wrapper">
              <div class="input-wrapper">
                <label for="First-Name" class="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  class="half-text-field w-input"
                  maxlength="256"
                  name="First-Name"
                  data-name="First Name"
                  placeholder=""
                  id="First-Name"
                  required
                  value={parentFname}
                  onChange={(e) => setParentFname(e.currentTarget.value)}
                />
              </div>
              <div class="input-wrapper">
                <label for="Password-2" class="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  class="half-text-field w-input"
                  maxlength="256"
                  name="Last-Name"
                  data-name="Last Name"
                  placeholder=""
                  id="Last-Name"
                  required
                  value={parentLname}
                  onChange={(e) => setParentLname(e.currentTarget.value)}
                />
              </div>
            </div>
            <div class="input-wrapper">
              <label for="Phone" class="form-label">
                Phone
              </label>
              <input
                onChange={(e) => setTel(e.currentTarget.value)}
                value={tel}
                type="tel"
                className="w-input"
                maxLength="256"
                name="Phone"
                data-name="Phone"
                placeholder=""
                id="Phone"
                required
              />
            </div>
            <div class="input-wrapper">
              <label for="Relationship" class="form-label">
                Relationship
              </label>
              <input
                type="text"
                class="w-input"
                maxlength="256"
                name="Relationship"
                data-name="Relationship"
                placeholder=""
                id="Relationship"
                value={relationship}
                required
                onChange={(e) => setRelationship(e.currentTarget.value)}
              />
            </div>
          </div>
          {loadingStatus ? (
            <ReactBootstrap.Spinner animation="border" variant="secondary" />
          ) : loading2Status ? (
            <button className="button login w-button" id="submitted">
              Submitted
            </button>
          ) : (
            <input
              onClick={signup}
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              className="button login w-button"
            />
          )}
        </form>
        {/* Add Family Form End */}

        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
}
