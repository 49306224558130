import axios from "axios";
export const ATTENDANCE_GET_REQUEST = "ATTENDANCE_GET_REQUEST";
export const ATTENDANCE_GET_SUCCESS = "ATTENDANCE_GET_SUCCESS";
export const ATTENDANCE_GET_FAILURE = "ATTENDANCE_GET_FAILURE";

//Thunk action creators
//Get all course thunk action creators
export const attendanceGetRequest = () => {
  return {
    type: ATTENDANCE_GET_REQUEST,
  };
};
export const attendanceGetSuccess = (data) => {
  return {
    type: ATTENDANCE_GET_SUCCESS,
    attendance: data,
  };
};

export const attendanceGetFailure = (message) => {
  return {
    type: ATTENDANCE_GET_FAILURE,
    message: message,
  };
};

//Get all attendance thunk actions
export const attendanceGetSuccessThunk = (lessonId) => {
  return (dispatch) => {
    const data = {};
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/attendance/lesson/${lessonId}`
      )
      .then((res) => {
        if (!res.data || res.data.length === 0) {
          data[0] = [];
        } else {
          data[0] = res.data;
        }
      })
      .then(console.log(data, "attendance"))
      .then(() => dispatch(attendanceGetSuccess(data)))
      .catch((err) => {
        dispatch(attendanceGetFailure(`post request error: ${err}`));
      });
  };
};
