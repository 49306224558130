import {
    LESSON_SUCCESS_ACTION,
    LESSON_FAILURE_ACTION
} from './actions'

const initialState = {
    lesson: [],
    message: ''
};

export const lessonReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case LESSON_SUCCESS_ACTION:
            return {lesson: action.lesson, message: ''};
          case LESSON_FAILURE_ACTION:
            return {lesson: [], message: action.message};
          default:
              return state; 
      }
};