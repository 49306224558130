import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addStudentThunk } from "../../Redux/addInfo/actions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { loadingToggleAction } from "../../Redux/loading/actions";
import { loading2ToggleAction } from "../../Redux/loading2/actions";
import * as ReactBootstrap from "react-bootstrap";

export default function FamilyAddStudent() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(false));
    dispatch(loading2ToggleAction(false));
  }, []);

  const loadingStatus = useSelector((state) => state.loadingStore.showLoading);
  const loading2Status = useSelector(
    (state) => state.loading2Store.showLoading
  );

  const infoAllFamilyArray = useSelector((state) => state.familyInfoStore);
  const [allFamilyArray, setallFamilyArray] = useState(
    infoAllFamilyArray.family
  );

  const [studentNum, setStudentNum] = useState(1);

  const handleStudentAdd = (e) => {
    e.preventDefault();
    setStudentNum((prev) => prev + 1);
  };

  const handleStudentDel = (e) => {
    e.preventDefault();
    setStudentNum(studentNum - 1);
  };

  const renderStudentDeleteBtn = (i) => {
    if (i >= 1) {
      return <button onClick={handleStudentDel}>-</button>;
    }
  };

  //Handle sidebar filter
  function handleSubmit(e) {
    dispatch(loadingToggleAction(true));
    e.preventDefault();
    let newFormData = new FormData(e.target);
    // Family Email
    let familyEmail = newFormData.getAll("familyEmail");
    // Other Info
    let firstName = newFormData.getAll("firstName");
    let lastName = newFormData.getAll("lastName");
    let dob = newFormData.getAll("dob");
    let hkid = newFormData.getAll("hkid");
    let studentInfo = [];
    for (let i = 0; i < firstName.length; i++) {
      studentInfo[i] = {
        firstName: firstName[i],
        lastName: lastName[i],
        dob: dob[i],
        hkid: hkid[i],
      };
    }
    console.log(studentInfo, "studentInfo");
    for (let i = 0; i < studentInfo.length; i++) {
      dispatch(addStudentThunk(familyEmail[0], studentInfo[i]));
    }
  }

  return (
    <div class="div-block-4">
      <div class="form-block w-form">
        {/* Add Student Form Start */}
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          class="form-3"
          onSubmit={handleSubmit}
        >
          <h4>Add New Child</h4>
          <label for="Search-Family">Family email</label>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={allFamilyArray.map((family) => family.family_email)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="familyEmail"
                label="Enter family email"
                required
              />
            )}
          />
          <div class="form-wrapper">
            <h4>Add Child account</h4>
            <button
              onClick={handleStudentAdd}
              className="button-2 small w-button"
            >
              + Child Account
            </button>
          </div>
          {/* Start */}
          {[...Array(studentNum)].map((Student, i) => {
            return (
              <div className="account-card">
                <h4>#{i + 1}</h4>
                {renderStudentDeleteBtn(i)}
                <div class="form-wrapper">
                  <div class="input-wrapper">
                    <label for="First-Name-2" class="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      class="half-text-field w-input"
                      maxlength="256"
                      data-name="First Name 2"
                      placeholder=""
                      id="First-Name-2"
                      required
                      name="firstName"
                    />
                  </div>
                  <div class="input-wrapper">
                    <label for="Password" class="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      class="half-text-field w-input"
                      maxlength="256"
                      data-name="Last Name 2"
                      placeholder=""
                      id="Last-Name-2"
                      required
                      name="lastName"
                    />
                  </div>
                </div>
                <div class="input-wrapper">
                  <label for="Email-3" class="form-label">
                    DOB
                  </label>
                  {/* <input
                    type="text"
                    class="w-input"
                    maxlength="256"
                    name="DOB"
                    data-name="DOB"
                    placeholder=""
                    id="DOB"
                    required
                    name="dob"
                  /> */}
                  <input
                    type="date"
                    className="dateInput"
                    name="dob"
                    class="half-text-field w-input"
                    required
                  />
                </div>
                <div class="input-wrapper">
                  <label for="Relationship-2" class="form-label">
                    HKID (Example: Y1234567)
                  </label>
                  <input
                    type="text"
                    class="w-input"
                    maxlength="256"
                    data-name="Relationship 2"
                    placeholder=""
                    id="Relationship-2"
                    name="hkid"
                    required
                  />
                </div>
              </div>
            );
          })}
          {loadingStatus ? (
            <ReactBootstrap.Spinner animation="border" variant="secondary" />
          ) : loading2Status ? (
            <button className="button login w-button" id="submitted">
              Submitted
            </button>
          ) : (
            <input
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              class="button w-button"
            />
          )}
        </form>
        {/* Add Student Form End */}

        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
}
