import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Routes, BrowserRouter as Router, Link, Route, Navigate, Outlet } from "react-router-dom";

import SkeletonArticle from "../../skeletons/SkeletonArticle";
import { loading2ToggleAction } from "../../Redux/loading2/actions";

import ImageUpload from './ImageUpload'
import Map from './Map'
import Navbar from './Navbar';

export default function Setting() {
const dispatch = useDispatch();
useEffect(() => {
    // dispatch(loading2ToggleAction(true))
}, []);
const loading2Status = useSelector((state) => state.loading2Store.showLoading);

  return (
    <>
        {loading2Status ? (
        <div className="content">
          <div className="announcement-skeleton-container">
            <div>
                {[1,2,3,4].map((a,i)=>(
                    <SkeletonArticle key={i}/>
                ))}
              <div className="notification-time"></div>
            </div>
          </div>
          </div>
      ) : (
        <div className="content">
            <Navbar />
            <Outlet />
            {/* <div className="d-flex flex-column align-items-center">
                <ImageUpload />
                <Map />
            </div> */}
        </div>
  )}
  </>)
}
