import { FAMINFO_GET_SUCCESS, FAMINFO_GET_FAILURE } from "./actions";

const initialState = {
  family: [],
  message: "",
};

export function familyInfoReducers(state = initialState, action) {
  switch (action.type) {
    case FAMINFO_GET_SUCCESS:
      return {
        family: action.family,
      };
    case FAMINFO_GET_FAILURE:
      return { ...state, message: action.message };
    default:
      return state;
  }
}
