import axios from "axios";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { loadingToggleAction } from "../loading/actions";
import { loading2ToggleAction } from "../loading2/actions";
import { courseGetSuccessThunk } from "../../Redux/courseInfo/actions";

export const LOGIN_CAMPUS_SUCCESS_ACTION = "LOGIN_CAMPUS_SUCCESS_ACTION";
export const LOGIN_FAMILY_SUCCESS_ACTION = "LOGIN_FAMILY_SUCCESS_ACTION";
export const LOGIN_FAILURE_ACTION = "LOGIN_FAILURE_ACTION";
export const LOGOUT_NOW_ACTION = "LOGOUT_NOW_ACTION";

//Action creators
export const loginCampusSuccessAction = () => {
  return {
    type: LOGIN_CAMPUS_SUCCESS_ACTION,
  };
};

export const loginFamilySuccessAction = () => {
  return {
    type: LOGIN_FAMILY_SUCCESS_ACTION,
  };
};

export const loginFailureAction = (message) => {
  return {
    type: LOGIN_FAILURE_ACTION,
    message: message,
  };
};

export const logoutNowAction = () => {
  return {
    type: LOGOUT_NOW_ACTION,
  };
};

//Thunk action
//=============
// Add Family Thunk
export const addFamilyThunk = (
  email,
  tel,
  parentFname,
  parentLname,
  relationship
) => {
  return async (dispatch) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/register/family/add`,
        {
          email: email,
          password: tel,
        }
      );
      const family_id = data.data[0].id;
      //wrong pw, data = {message: 'Wrong pw'}
      console.log(family_id);

      if (family_id == null) {
        dispatch(loginFailureAction("Unknown error"));
      } else {
        console.log(`successful add family ac, familyId: ${family_id}`);
        console.log(`${family_id}, ${parentFname}, ${parentLname}`);
        const parentInfo = await axios.post(
          `${process.env.REACT_APP_API_SERVER}/api/register/parentEdmis/${family_id}`,

          {
            info: {
              firstName: parentFname,
              lastName: parentLname,
              relationship,
              tel,
            },
          }
        );
        console.log(parentInfo);
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500);
        dispatch(loading2ToggleAction(true));
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 2000);
        dispatch(familyGetSuccessThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addParentThunk = (familyEmail, parentInfoArray) => {
  return async (dispatch) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/register/family/findId
        `,
        {
          email: familyEmail,
        }
      );
      const family_id = data.data[0].id;
      //wrong pw, data = {message: 'Wrong pw'}
      console.log(family_id);

      if (family_id == null) {
        console.log("Unknown error");
      } else {
        console.log(`successful add family ac, familyId: ${family_id}`);
        const parentInfo = await axios.post(
          `${process.env.REACT_APP_API_SERVER}/api/register/parentEdmis/${family_id}`,

          {
            info: { ...parentInfoArray },
          }
        );
        console.log(parentInfo);
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500);
        dispatch(loading2ToggleAction(true));
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 2000);
        dispatch(familyGetSuccessThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addStudentThunk = (familyEmail, studentInfoArray) => {
  return async (dispatch) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/register/family/findId
        `,
        {
          email: familyEmail,
        }
      );
      const family_id = data.data[0].id;
      //wrong pw, data = {message: 'Wrong pw'}
      console.log(family_id);

      if (family_id == null) {
        console.log("Unknown error");
      } else {
        console.log(`successful add family ac, familyId: ${family_id}`);
        const studentInfo = await axios.post(
          `${process.env.REACT_APP_API_SERVER}/api/register/studentEdmis/${family_id}`,

          {
            info: { ...studentInfoArray },
          }
        );
        console.log(studentInfo);
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500);
        dispatch(loading2ToggleAction(true));
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 2000);
        dispatch(familyGetSuccessThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Add Course Thunk
export const addCourseThunk = (otherInfo, lessonTime) => {
  let token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      // const data = await axios.post(
      //   `${process.env.REACT_APP_API_SERVER}/api/register/campus/findId
      //   `,
      //   {
      //     campus_name: campusName,
      //   }
      // );
      // const campus_id = data.data[0].id;
      // //wrong pw, data = {message: 'Wrong pw'}
      // console.log(campus_id);
      const campus_data = await axios.get(
        `${process.env.REACT_APP_API_SERVER}/api/info/campus`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const campus_id = campus_data.data[0].id;

      if (campus_id == null) {
        console.log("Unknown error");
      } else {
        console.log(`successful find campus ac, campusId: ${campus_id}`);
        console.log(otherInfo);

        const course_data = await axios.post(
          `${process.env.REACT_APP_API_SERVER}/api/register/course`,
          {
            info: { ...otherInfo, campus_id },
          }
        );
        const course_id = course_data.data[0].id;
        console.log(course_id, "course_id");

        for (let i = 0; i < lessonTime.length; i++) {
          await axios.post(
            `${process.env.REACT_APP_API_SERVER}/api/register/lesson`,
            {
              info: { ...lessonTime[i], course_id },
            }
          );
        }
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500);
        dispatch(loading2ToggleAction(true));
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 2000);
        dispatch(courseGetSuccessThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};
