import axios from "axios";

export const COURSE_SUCCESS_ACTION = 'COURSE_SUCCESS_ACTION';
export const COURSE_FAILURE_ACTION = 'COURSE_FAILURE_ACTION';

//Thunk action creator
export const courseSuccess = (course)=>{
    return({
        type: COURSE_SUCCESS_ACTION,
        course: course
    })
}

export const courseFailure = (message)=>{
    return({
        type: COURSE_FAILURE_ACTION,
        message: message
    })
}

//Thunk actions

//Get Request
export const courseGetCampusThunk = (campusId) =>{
    return (dispatch)=>{
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/course/campus/${campusId}`).then((res)=>{
            console.log(res.data)
            if(res.data.length == 0) {
                console.log('no course')
                // window.location = `/error`
            }
            dispatch(courseSuccess(res.data))
        })
        .catch((err)=>{
            console.log(err)
            dispatch(courseFailure(`post request error: ${err}`))
        })
    }
}

