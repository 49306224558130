import {
    LOCATION_SUCCESS_ACTION,
    LOCATION_FAILURE_ACTION
} from './actions'

const initialState = {
    updated: [],
    message: ''
};

export const locationReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case LOCATION_SUCCESS_ACTION:
            return {updated: action.updated, message: action.message};
          case LOCATION_FAILURE_ACTION:
            return {updated: [], message: action.message};
          default:
              return state; 
      }
};