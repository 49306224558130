import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { courseGetSuccessThunk } from "../../Redux/courseInfo/actions";
import { infoGetAllCampusThunk } from "../../Redux/info/actions";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";

import EnrolmentNav from "./EnrolmentNav";

export default function Enrolment() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(courseGetSuccessThunk());
    dispatch(infoGetAllCampusThunk());
  }, []);
  return (
    <div>
      <EnrolmentNav />
      <Outlet />
    </div>
  );
}
