import axios from "axios";

export const LESSON_SUCCESS_ACTION = 'LESSON_SUCCESS_ACTION';
export const LESSON_FAILURE_ACTION = 'LESSON_FAILURE_ACTION';

//Thunk action creator
export const lessonSuccess = (lesson)=>{
    return({
        type: LESSON_SUCCESS_ACTION,
        lesson: lesson
    })
}

export const lessonFailure = (message)=>{
    return({
        type: LESSON_FAILURE_ACTION,
        message: message
    })
}

//Thunk actions

//Get Request
export const lessonGetThunk = (id, courseId) =>{
    return (dispatch)=>{
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/lesson/${id}/${courseId}`).then((res)=>{
            console.log(res.data)
            dispatch(lessonSuccess(res.data))
        })
        .catch((err)=>{
            console.log(err)
            dispatch(lessonFailure(`post request error: ${err}`))
        })
    }
}

