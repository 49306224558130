import React from "react";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

export default function FamilyNav() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(familyGetSuccessThunk());
  // }, []);
  return (
    <div>
      <div className="page-nav">
        <Link to="/account/search" className="nav-item">
          Search
        </Link>
        <Link to="/account/family" className="nav-item">
          Family
        </Link>
        <Link to="/account/parent" className="nav-item">
          Parents
        </Link>
        <Link to="/account/student" className="nav-item">
          Students
        </Link>
      </div>
      <div className="div-block-4"></div>
    </div>
  );
}
