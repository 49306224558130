import React from 'react';
import { Link } from "react-router-dom";


export default function NotFound() {
  return(
  <>
    <div className='not-found d-flex flex-column align-items-center'>
      <h3>Sorry! The page you are looking for is no longer available.</h3>
      <Link to='/' className="w-nav-brand"><h4>Back to Home</h4></Link>
    </div>
  </>)
}
