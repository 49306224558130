import {
    ANNOUNCEMENT_SUCCESS_ACTION,
    ANNOUNCEMENT_FAILURE_ACTION
} from './actions'

const initialState = {
    announcement: [],
    message: ''
};

export const announcementReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case ANNOUNCEMENT_SUCCESS_ACTION:
            return {...state, announcement: action.announcement};
          case ANNOUNCEMENT_FAILURE_ACTION:
            return {...state, message: action.message};
          default:
              return state; 
      }
};