import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addParentThunk } from "../../Redux/addInfo/actions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { loadingToggleAction } from "../../Redux/loading/actions";
import { loading2ToggleAction } from "../../Redux/loading2/actions";
import { infoGetAllCampusThunk } from "../../Redux/info/actions";
import { addCourseThunk } from "../../Redux/addInfo/actions";
import * as ReactBootstrap from "react-bootstrap";

export default function Enrolment() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(false));
    dispatch(loading2ToggleAction(false));
  }, []);

  const loadingStatus = useSelector((state) => state.loadingStore.showLoading);
  const loading2Status = useSelector(
    (state) => state.loading2Store.showLoading
  );

  const infoGetAllCampusArray = useSelector((state) => state.infoStore);
  const [allCampusArray, setallCampusArray] = useState(
    infoGetAllCampusArray.infoAll
  );

  console.log(allCampusArray);

  const [lessonNum, setLessonNum] = useState(1);

  const handleLessonAdd = (e) => {
    e.preventDefault();
    setLessonNum((prev) => prev + 1);
  };

  const handleLessonDel = (e) => {
    e.preventDefault();
    setLessonNum(lessonNum - 1);
  };

  const renderLessonDeleteBtn = (i) => {
    if (i >= 1) {
      return <button onClick={handleLessonDel}>-</button>;
    }
  };

  //Handle sidebar filter
  function handleSubmit(e) {
    dispatch(loadingToggleAction(true));
    e.preventDefault();
    let newFormData = new FormData(e.target);
    // Other Info
    let courseName = newFormData.getAll("courseName");
    let price = newFormData.getAll("price");
    let category = newFormData.getAll("category");
    let capacity = newFormData.getAll("capacity");
    let startDateTime = newFormData.getAll("startDateTime");
    let endDateTime = newFormData.getAll("endDateTime");
    let otherInfo = [];
    let lessonTime = [];
    for (let i = 0; i < category.length; i++) {
      otherInfo[i] = {
        category: category[i],
        quota: parseInt(capacity[i]),
        title: courseName[i],
        price: parseInt(price[i]),
      };
    }
    for (let i = 0; i < startDateTime.length; i++) {
      lessonTime[i] = {
        startDateTime: startDateTime[i].concat(":00Z"),
        endDateTime: endDateTime[i].concat(":00Z"),
      };
    }
    console.log(lessonTime, "lessonTime");
    console.log(otherInfo, "other info");
    dispatch(addCourseThunk(otherInfo[0], lessonTime)); //auth campus_id
  }

  return (
    <div class="div-block-4">
      <div class="form-block w-form">
        {/* Add Parent Form Start */}
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          method="get"
          class="form-3"
          onSubmit={handleSubmit}
        >
          <h4>Add Course</h4>
          {/* <label for="Search-Family">Campus Name</label>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={allCampusArray.map((campus) => campus.company_name)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="campusName"
                label="Enter campus name"
                required
              />
            )}
          /> */}
          <div class="form-wrapper"></div>
          {/* Start */}
          <div className="account-card">
            <div class="form-wrapper">
              <div class="input-wrapper"></div>
            </div>
            <div class="input-wrapper">
              <label for="Email-3" class="form-label">
                Course Name
              </label>
              <input
                type="text"
                class="w-input"
                maxlength="256"
                data-name="Email"
                placeholder=""
                id="Email-3"
                name="courseName"
                required
              />
            </div>
            {/* category select */}
            <label for="field" className="form-label">
              Category
            </label>
            <select
              id="field"
              name="category"
              data-name="Field"
              className="w-select"
              required
              // value={category}
              // onChange={handleCatChange}
            >
              <option selected disabled hidden>
                Select a category
              </option>
              <option value="Languages">Languages</option>
              <option value="Maths">Maths</option>
              <option value="Sports">Sports</option>
              <option value="Arts and crafts">Arts &amp; Crafts</option>
              <option value="Performing Arts">Performing Arts</option>
              <option value="Musics">Musics</option>
              <option value="STEM">STEM</option>
              <option value="Others">Others</option>
            </select>

            <div class="input-wrapper">
              <label for="Email-3" class="form-label">
                Capacity
              </label>
              <input
                type="number"
                class="w-input"
                maxlength="256"
                data-name="Email"
                placeholder=""
                id="Email-3"
                name="capacity"
                required
              />
            </div>

            <div class="input-wrapper">
              <label for="Email-3" class="form-label">
                Price
              </label>
              <input
                type="number"
                class="w-input"
                maxlength="256"
                data-name="Email"
                placeholder=""
                id="Email-3"
                name="price"
                required
              />
            </div>
            <div class="form-wrapper">
              <button
                onClick={handleLessonAdd}
                className="button-2 small w-button"
              >
                + Add lesson
              </button>
            </div>
            {/* Start */}
            {[...Array(lessonNum)].map((lesson, i) => {
              return (
                <div className="account-card">
                  <h4>#{i + 1}</h4>
                  <div className="datetimeContainer">
                    <input
                      type="datetime-local"
                      className="datetimeInput"
                      name="startDateTime"
                      required
                    />
                    to
                    <input
                      type="datetime-local"
                      className="datetimeInput"
                      name="endDateTime"
                      required
                    />
                  </div>
                  {renderLessonDeleteBtn(i)}
                </div>
              );
            })}
            {/* End */}
          </div>
          {loadingStatus ? (
            <ReactBootstrap.Spinner animation="border" variant="secondary" />
          ) : loading2Status ? (
            <button className="button login w-button" id="submitted">
              Submitted
            </button>
          ) : (
            <input
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              class="button w-button"
            />
          )}
        </form>
        {/* Add Parent Form End */}

        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
}
