const locationInfo = {
    area : [
        {
            "id": 1,
            "area_name": "Kennedy Town",
            "district_id": 15
        },
        {
            "id": 2,
            "area_name": "Shek Tong Tsui",
            "district_id": 15
        },
        {
            "id": 3,
            "area_name": "Sai Ying Pun",
            "district_id": 15
        },
        {
            "id": 4,
            "area_name": "Sheung Wan",
            "district_id": 15
        },
        {
            "id": 5,
            "area_name": "Central",
            "district_id": 15
        },
        {
            "id": 6,
            "area_name": "Admiralty",
            "district_id": 15
        },
        {
            "id": 7,
            "area_name": "Mid-levels",
            "district_id": 15
        },
        {
            "id": 8,
            "area_name": "Peak",
            "district_id": 15
        },
        {
            "id": 9,
            "area_name": "Wan Chai",
            "district_id": 18
        },
        {
            "id": 10,
            "area_name": "Causeway Bay",
            "district_id": 18
        },
        {
            "id": 11,
            "area_name": "Happy Valley",
            "district_id": 18
        },
        {
            "id": 12,
            "area_name": "Tai Hang",
            "district_id": 18
        },
        {
            "id": 13,
            "area_name": "So Kon Po",
            "district_id": 18
        },
        {
            "id": 14,
            "area_name": "Jardine's Lookout",
            "district_id": 18
        },
        {
            "id": 15,
            "area_name": "Tin Hau",
            "district_id": 16
        },
        {
            "id": 16,
            "area_name": "Braemar Hill",
            "district_id": 16
        },
        {
            "id": 17,
            "area_name": "North Point",
            "district_id": 16
        },
        {
            "id": 18,
            "area_name": "Quarry Bay",
            "district_id": 16
        },
        {
            "id": 19,
            "area_name": "Sai Wan Ho",
            "district_id": 16
        },
        {
            "id": 20,
            "area_name": "Shau Kei Wan",
            "district_id": 16
        },
        {
            "id": 21,
            "area_name": "Chai Wan",
            "district_id": 16
        },
        {
            "id": 22,
            "area_name": "Siu Sai Wan",
            "district_id": 16
        },
        {
            "id": 23,
            "area_name": "Pok Fu Lam",
            "district_id": 17
        },
        {
            "id": 24,
            "area_name": "Aberdeen",
            "district_id": 17
        },
        {
            "id": 25,
            "area_name": "Ap Lei Chau",
            "district_id": 17
        },
        {
            "id": 26,
            "area_name": "Wong Chuk Hang",
            "district_id": 17
        },
        {
            "id": 27,
            "area_name": "Shouson Hill",
            "district_id": 17
        },
        {
            "id": 28,
            "area_name": "Repulse Bay",
            "district_id": 17
        },
        {
            "id": 29,
            "area_name": "Chung Hom Kok",
            "district_id": 17
        },
        {
            "id": 30,
            "area_name": "Stanley",
            "district_id": 17
        },
        {
            "id": 31,
            "area_name": "Tai Tam",
            "district_id": 17
        },
        {
            "id": 32,
            "area_name": "Shek O",
            "district_id": 17
        },
        {
            "id": 33,
            "area_name": "Tsim Sha Tsui",
            "district_id": 14
        },
        {
            "id": 34,
            "area_name": "Yau Ma Tei",
            "district_id": 14
        },
        {
            "id": 35,
            "area_name": "West Kowloon Reclamation",
            "district_id": 14
        },
        {
            "id": 36,
            "area_name": "King's Park",
            "district_id": 14
        },
        {
            "id": 37,
            "area_name": "Mong Kok",
            "district_id": 14
        },
        {
            "id": 38,
            "area_name": "Tai Kok Tsui",
            "district_id": 14
        },
        {
            "id": 39,
            "area_name": "Mei Foo",
            "district_id": 12
        },
        {
            "id": 40,
            "area_name": "Lai Chi Kok",
            "district_id": 12
        },
        {
            "id": 41,
            "area_name": "Cheung Sha Wan",
            "district_id": 12
        },
        {
            "id": 42,
            "area_name": "Sham Shui Po",
            "district_id": 12
        },
        {
            "id": 43,
            "area_name": "Shek Kip Mei",
            "district_id": 12
        },
        {
            "id": 44,
            "area_name": "Yau Yat Tsuen",
            "district_id": 12
        },
        {
            "id": 45,
            "area_name": "Tai Wo Ping",
            "district_id": 12
        },
        {
            "id": 46,
            "area_name": "Stonecutters Island",
            "district_id": 12
        },
        {
            "id": 47,
            "area_name": "Hung Hom",
            "district_id": 10
        },
        {
            "id": 48,
            "area_name": "To Kwa Wan",
            "district_id": 10
        },
        {
            "id": 49,
            "area_name": "Ma Tau Kok",
            "district_id": 10
        },
        {
            "id": 50,
            "area_name": "Ma Tau Wai",
            "district_id": 10
        },
        {
            "id": 51,
            "area_name": "Kai Tak",
            "district_id": 10
        },
        {
            "id": 52,
            "area_name": "Kowloon City",
            "district_id": 10
        },
        {
            "id": 53,
            "area_name": "Ho Man Tin",
            "district_id": 10
        },
        {
            "id": 54,
            "area_name": "Kowloon Tong",
            "district_id": 10
        },
        {
            "id": 55,
            "area_name": "Beacon Hill",
            "district_id": 10
        },
        {
            "id": 56,
            "area_name": "San Po Kong",
            "district_id": 13
        },
        {
            "id": 57,
            "area_name": "Wong Tai Sin",
            "district_id": 13
        },
        {
            "id": 58,
            "area_name": "Tung Tau",
            "district_id": 13
        },
        {
            "id": 59,
            "area_name": "Wang Tau Hom",
            "district_id": 13
        },
        {
            "id": 60,
            "area_name": "Lok Fu",
            "district_id": 13
        },
        {
            "id": 61,
            "area_name": "Diamond Hill",
            "district_id": 13
        },
        {
            "id": 62,
            "area_name": "Tsz Wan Shan",
            "district_id": 13
        },
        {
            "id": 63,
            "area_name": "Ngau Chi Wan",
            "district_id": 13
        }
    ],
    district : [
        {
            "id": 1,
            "district_name": "Islands",
            "region": "New Territories"
        },
        {
            "id": 2,
            "district_name": "Kwai Tsing",
            "region": "New Territories"
        },
        {
            "id": 3,
            "district_name": "North",
            "region": "New Territories"
        },
        {
            "id": 4,
            "district_name": "Sai Kung",
            "region": "New Territories"
        },
        {
            "id": 5,
            "district_name": "Sha Tin",
            "region": "New Territories"
        },
        {
            "id": 6,
            "district_name": "Tai Po",
            "region": "New Territories"
        },
        {
            "id": 7,
            "district_name": "Tsuen Wan",
            "region": "New Territories"
        },
        {
            "id": 8,
            "district_name": "Tuen Mun",
            "region": "New Territories"
        },
        {
            "id": 9,
            "district_name": "Yuen Long",
            "region": "New Territories"
        },
        {
            "id": 10,
            "district_name": "Kowloon City",
            "region": "Kowloon"
        },
        {
            "id": 11,
            "district_name": "Kwun Tong",
            "region": "Kowloon"
        },
        {
            "id": 12,
            "district_name": "Sham Shui Po",
            "region": "Kowloon"
        },
        {
            "id": 13,
            "district_name": "Wong Tai Sin",
            "region": "Kowloon"
        },
        {
            "id": 14,
            "district_name": "Yau Tsim Mong",
            "region": "Kowloon"
        },
        {
            "id": 15,
            "district_name": "Central and Western",
            "region": "Hong Kong Island"
        },
        {
            "id": 16,
            "district_name": "Eastern",
            "region": "Hong Kong Island"
        },
        {
            "id": 17,
            "district_name": "Southern",
            "region": "Hong Kong Island"
        },
        {
            "id": 18,
            "district_name": "Wan Chai",
            "region": "Hong Kong Island"
        }
    ]
} 

module.exports.locationInfo = locationInfo