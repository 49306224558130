import { ATTENDANCE_GET_SUCCESS, ATTENDANCE_GET_FAILURE } from "./actions";

const initialState = {
  attendance: [],
  message: "",
};

export function attendanceReducers(state = initialState, action) {
  switch (action.type) {
    case ATTENDANCE_GET_SUCCESS:
      return {
        attendance: action.attendance,
      };
    case ATTENDANCE_GET_FAILURE:
      return { ...state, message: action.message };
    default:
      return state;
  }
}
