import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import placeholder_image from "../../../assets/images/placeholder-image.jpeg";
import { courseGetSuccessThunk } from "../../../Redux/courseInfo/actions";
import { Modal } from "react-bootstrap";
import success from "../../../assets/images/success.png";

export default function SearchResults(props) {
  const dispatch = useDispatch();
  // Handle student age
  const handleAge = (dob) => {
    let timeDiff = new Date() - new Date(dob);
    let years = Math.floor(timeDiff / 1000 / 3600 / 24 / 365);
    let months = Math.floor(timeDiff / 1000 / 3600 / 30);
    if (years > 0) {
      return `${years}`;
    } else {
      return `${months}m`;
    }
  };

  function deleteCourse(course_id) {
    axios.delete(
      `${process.env.REACT_APP_API_SERVER}/api/course/courseId/${course_id}`
    );
    dispatch(courseGetSuccessThunk(1)); // auth campus ID
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.course && props.course.length > 0 ? (
        props.course.map((course, i) => (
          <div className="family-card" key={i}>
            <div className="columns-3 w-row" id="courseContainer">
              <div className="w-col w-col-4" className="courseDetails">
                <div className="family-id">Course ID #{course.id}</div>
                <h5 className="family-email">{course.title}</h5>
                <div>{course.category}</div>
              </div>
              <div className="w-col w-col-3" className="courseDetails">
                <h6>Lesson(s)</h6>
                {/* Parent start */}
                {course.lesson[0] && course.lesson[0].length > 0 ? (
                  <ul role="list">
                    {course.lesson[0].map((lesson, i) => (
                      <li key={i}>
                        {lesson.startDateTime} - {lesson.endDateTime}{" "}
                      </li>
                    ))}{" "}
                  </ul>
                ) : (
                  <h5 className="makeitcentre">No lesson found</h5>
                )}
                {/* Parent end */}
              </div>
              <div className="w-col w-col-3" className="courseDetails">
                <h6>Enrolment(s)</h6>
                {course.enrolment[0] && course.enrolment[0].length > 0 ? (
                  <ul role="list">
                    {course.enrolment[0].map((student, i) => (
                      <li key={i}>
                        {student.firstName} {student.lastName} (
                        {handleAge(student.dob)})
                      </li>
                    ))}{" "}
                  </ul>
                ) : (
                  <h5 className="makeitcentre">No student enrolled</h5>
                )}
              </div>
              <div className="w-col w-col-3" className="courseDetails">
                <h6>Course Image</h6>
                <img
                  src={course.coursePic || placeholder_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholder_image;
                  }}
                  loading="lazy"
                  alt=""
                  className="banner"
                />
              </div>
              {/* <div className="imageButtonContainer">
                <button
                  onClick={() => {
                    deleteCourse(course.id);

                    setShowModal(true);
                    setTimeout(() => {
                      setShowModal(false);
                    }, 2000);
                    dispatch(courseGetSuccessThunk(1)); // auth campus ID
                    // api endpoint, item
                  }}
                  className="button w-button"
                  id="deleteImage"
                >
                  Delete Course
                </button>
              </div> */}
              {/* <DetailedInfoModal family={family}></DetailedInfoModal> */}
              {/* On click submit Modal */}
              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered="true"
                backdrop="true"
                keyboard={false}
                className="d-flex justify-content-center"
              >
                <Modal.Body>
                  <>
                    <img src={success} loading="lazy" width="30" height="30" />
                    <span>Course deleted</span>
                  </>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        ))
      ) : (
        <>
          <h5 className="makeitcentre">No Course found</h5>
        </>
      )}
    </>
  );
}
