import React from "react";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

export default function EnrolmentNav() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(familyGetSuccessThunk());
  // }, []);
  return (
    <div>
      <div className="page-nav">
        <Link to="/course/list" className="nav-item">
          Course List
        </Link>
        <Link to="/course/add" className="nav-item">
          Add Course
        </Link>

        <Link to="/course/image_upload" className="nav-item">
          Update Images
        </Link>
      </div>
      <div className="div-block-4"></div>
    </div>
  );
}
