import axios from "axios";
export const FAMINFO_GET_REQUEST = "FAMINFO_GET_REQUEST";
export const FAMINFO_GET_SUCCESS = "FAMINFO_GET_SUCCESS";
export const FAMINFO_GET_FAILURE = "FAMINFO_GET_FAILURE";

//Thunk action creators
//Get all family thunk action creators
export const familyGetRequest = () => {
  return {
    type: FAMINFO_GET_REQUEST,
  };
};
export const familyGetSuccess = (data) => {
  return {
    type: FAMINFO_GET_SUCCESS,
    family: data.family,
  };
};

export const familyGetFailure = (message) => {
  return {
    type: FAMINFO_GET_FAILURE,
    message: message,
  };
};

export const familyGetSuccessThunk = () => {
  return (dispatch) => {
    const data = {};
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/allFamily`)
      // .get(`${process.env.REACT_APP_API_SERVER}/api/info/allFamily`)
      .then((res) => {
        data.family = res.data;
        for (let i = 0; i < data.family.length; i++) {
          const parent = {};
          const student = {};
          axios
            .get(
              `${process.env.REACT_APP_API_SERVER}/api/info/familyId/${data.family[i].id}/allFamilyParent`
            )
            // .get(
            //   `${process.env.REACT_APP_API_SERVER}/api/info/familyId/${data.family[i].id}/allFamilyParent`
            // )
            .then((res) => {
              if (!res.data || res.data.length === 0) {
                parent[0] = [];
              } else {
                parent[0] = res.data;
              }
            })
            .then(() => {
              axios
                .get(
                  `${process.env.REACT_APP_API_SERVER}/api/info/familyId/${data.family[i].id}/allFamilyStudent`
                )
                .then((res) => {
                  if (!res.data || res.data.length === 0) {
                    student[0] = [];
                  } else {
                    student[0] = res.data;
                  }
                });
            });
          data.family[i].parent = parent;
          data.family[i].student = student;
        }
        dispatch(familyGetSuccess(data));
      })
      // .then(() => dispatch(scoreGetSuccess(data)))
      .catch((err) => {
        dispatch(familyGetFailure(`post request error: ${err}`));
      });
  };
};
