import {
    REVIEW_SUCCESS_ACTION,
    REVIEW_FAILURE_ACTION
} from './actions'

const initialState = {
    review: [],
    message: ''
};

export const reviewReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case REVIEW_SUCCESS_ACTION:
            return {review: action.review, message: ''};
          case REVIEW_FAILURE_ACTION:
            return {review: [], message: action.message};
          default:
              return state; 
      }
};