import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

//Import Components below
import Navbar from "./Components/Layout/Navbar";
import Announcement from "./Components/Announcement/Announcement";
import Profile from "./Components/Profile/Profile";
import SearchProfile from "./Components/Profile/SearchProfile/SearchProfile";
import FamilyAddNew from "./Components/Profile/FamilyAddNew";
import FamilyAddParent from "./Components/Profile/FamilyAddParent";
import FamilyAddStudent from "./Components/Profile/FamilyAddStudent";
import Setting from "./Components/Setting/Setting";
import Map from "./Components/Setting/Map";
import ImageUpload from "./Components/Setting/ImageUpload";
import Enrolment from "./Components/Enrolment/Enrolment";
import SearchEnrolment from "./Components/Enrolment/SearchEnrolment/SearchEnrolment";
import CampusAddCourse from "./Components/Enrolment/CampusAddCourse";
import { LoginFormCampus } from "./Components/LoginFormCampus";
import ImageUploadCourse from "./Components/Enrolment/ImageUpload";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import NotFound from "./Components/Layout/NotFound";
import ProfileInfo from "./Components/Setting/ProfileInfo";

const RequireAuth = ({ children, redirectTo }) => {
  let authRole = useSelector((state) => state.authStore.authRole);
  return authRole != "" ? children : <Navigate to={redirectTo} />;
};

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: `"Nunito", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="section wf-section">
          <Router>
            <Navbar />
            <main className="content">
              <Routes>
                <Route path="" element={<Navigate to="/profile" />} />

                <Route
                  path="account"
                  element={
                    <RequireAuth redirectTo="/login">
                      <Profile />
                    </RequireAuth>
                  }
                >
                  <Route path="" element={<SearchProfile />}>
                    <Route path="search" element={<SearchProfile />} />
                  </Route>
                  <Route path="family" element={<FamilyAddNew />} />
                  <Route path="parent" element={<FamilyAddParent />} />
                  <Route path="student" element={<FamilyAddStudent />} />
                </Route>
                <Route
                  path="announcement"
                  element={
                    <RequireAuth redirectTo="/login">
                      <Announcement />
                    </RequireAuth>
                  }
                />
                {/* Enrolment */}
                <Route
                  path="course"
                  element={
                    <RequireAuth redirectTo="/login">
                      <Enrolment />
                    </RequireAuth>
                  }
                >
                  <Route path="" element={<Navigate to="/course/list" />} />
                  <Route path="add" element={<CampusAddCourse />} />
                  <Route path="list" element={<SearchEnrolment />} />
                  <Route path="course" element={<CampusAddCourse />} />
                  <Route path="image_upload" element={<ImageUploadCourse />} />
                </Route>
                {/* Enrolment */}

                <Route
                  path="profile"
                  element={
                    <RequireAuth redirectTo="/login">
                      <Setting />
                    </RequireAuth>
                  }
                >
                  <Route path="" element={<Navigate to="/profile/info" />} />
                  <Route path="info" element={<ProfileInfo />} />
                  <Route path="map" element={<Map />} />
                  <Route path="image_upload" element={<ImageUpload />} />
                </Route>

                <Route path="login" element={<LoginFormCampus />} />
                <Route path="error" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </Router>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
