import React, { useEffect } from "react";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
} from "react-router-dom";
// import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { useSelector, useDispatch } from "react-redux";
import member_icon from "../../assets/images/team-member-icon.png";
import edmis_logo from "../../assets/images/edmis-logo.png";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import { logoutNowThunk, loginFailureAction } from "../../Redux/auth/actions";


export default function Navbar() {
  const dispatch = useDispatch();
  let authRole = useSelector((state) => state.authStore.authRole);
  const info = useSelector((state) => state.infoStore.info)[0]
  useEffect(() => {
    dispatch(familyGetSuccessThunk());
  }, []);

let company_name = info?.company_name
let logo = info?.logo


  return (
    <div
      data-animation="over-right"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      <div className="navbar-container w-container">
        <Link
          to="/"
          aria-current="page"
          className="company-logo w-nav-brand w--current"
        >
          <img
            sizes="(max-width: 479px) 24vw, (max-width: 767px) 17vw, (max-width: 991px) 14vw, (max-width: 1279px) 10vw, 8vw"
            src={edmis_logo}
            loading="lazy"
            alt=""
            className="image"
          />
        </Link>
      
        {authRole ?
        <div className="user-wrapper">
          <img
            loading="lazy"
            src={logo || member_icon}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src=member_icon;
            }}
            alt=""
            className="nav-user-icon"
          />
          <div className="nav-user-name">{company_name}</div>
          <div className="nav-user-title">admin</div>
        </div>
        : null}

      <nav role="navigation" className="navbar-wrapper w-nav-menu">
        {authRole ? <Link to="/account" className="nav-link w-nav-link">Accounts</Link> : null}
        {authRole ? <Link to="/announcement" className="nav-link w-nav-link">Announcements</Link> : null}
        {authRole ? <Link to="/course" className="nav-link w-nav-link">Courses</Link> : null}
        {authRole ? <Link to="/profile" className="nav-link w-nav-link">Profile</Link> : null}
        {!authRole ? <Link to="/login" className="nav-link w-nav-link" onClick={() => dispatch(loginFailureAction())}>Login</Link> : null}

        {authRole ? <span onClick={()=>dispatch(logoutNowThunk())} className="w-nav-link logout-btn">Logout</span> : null}
      </nav>

        <div className="menu-button w-nav-button">
          <div className="w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
  );
}
{/* <div class="section wf-section">
      
    </div> */}
