import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
} from "react-router-dom";

import SkeletonArticle from "../../skeletons/SkeletonArticle";
import { loading2ToggleAction } from "../../Redux/loading2/actions";
import {
  infoGetCampusThunk,
  infoPutCampusThunk,
} from "../../Redux/info/actions";
import { courseGetSuccessThunk } from "../../Redux/courseInfo/actions";

import success from "../../assets/images/success.png";
import placeholder_image from "../../assets/images/placeholder-image.jpeg";
import placeholder_image_500 from "../../assets/images/placeholder-image-p-500.jpeg";
import { locationInfo } from "../../LocationInfo";

export default function CampusInfo() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(loading2ToggleAction(true))
    dispatch(infoGetCampusThunk());
    dispatch(courseGetSuccessThunk());
  }, []);

  const info = useSelector((state) => state.infoStore.info)[0];
  const loading2Status = useSelector(
    (state) => state.loading2Store.showLoading
  );

  let company_name = info?.company_name;
  let email = info?.email;
  let tel = info?.tel;
  let whatsapp = info?.whatsapp;
  let website = info?.website;
  let officeHours = info?.officeHours;
  let description = info?.description;
  let area_id = info?.area_id;
  let subAddress = info?.subAddress;
  //Images
  let logo = info?.logo;
  let banner = info?.banner;
  //Location
  let area_name = locationInfo.area.find((a) => a.id === area_id)?.area_name;
  let district_id = locationInfo.area.find(
    (a) => a.id === area_id
  )?.district_id;
  let district_name = locationInfo.district.find(
    (a) => a.id === district_id
  )?.district_name;
  let region = locationInfo.district.find((a) => a.id === district_id)?.region;

  const [showModal, setShowModal] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showError, setShowError] = useState(false);
  //Edit info items
  const [newSchoolName, setSchoolName] = useState(company_name || "");
  const [newEmail, setEmail] = useState(email || "");
  const [newTel, setTel] = useState(tel || "");
  const [newWhatsapp, setWhatsapp] = useState(whatsapp || "");
  const [newWebsite, setWebsite] = useState(website || "");
  const [newOfficeHours, setOfficeHours] = useState(officeHours || "");
  const [newDescription, setDescription] = useState(description || "");
  const [newAreaId, setAreaId] = useState(area_id || "");
  const [newSubAddress, setSubAddress] = useState(subAddress || "");
  //Locations
  const [newRegion, setRegion] = useState(region || "");
  const [newDistrict, setDistrict] = useState(district_name || "");
  const [newArea, setArea] = useState(area_name || "");

  const handleShowModal = () => {
    //Set info
    setSchoolName(info.company_name);
    setEmail(info.email);
    setTel(info.tel);
    setWhatsapp(info.whatsapp);
    setWebsite(info.website);
    setOfficeHours(info.officeHours);
    setDescription(info.description);
    setAreaId(info.area_id);
    setSubAddress(info.subAddress);
    //Location
    setRegion(region);
    setDistrict(district_name);
    setArea(area_name);
    //Open modal
    setShowModal(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (newArea === "") {
      setShowModal(false);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 2000);
      return;
    }
    let newAreaId =
      locationInfo.area.find((a) => a.area_name === newArea)?.id || area_id;
    console.log(newAreaId);

    let info = {
      company_name: newSchoolName,
      email: newEmail,
      tel: newTel,
      whatsapp: newWhatsapp,
      website: newWebsite,
      officeHours: newOfficeHours,
      description: newDescription,
      area_id: newAreaId,
      subAddress: newSubAddress,
    };
    dispatch(infoPutCampusThunk(info));

    setShowModal(false);
    setShowSaved(true);
    setTimeout(() => {
      setShowSaved(false);
    }, 2000);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowModal(false);
    setShowCancel(true);
  };

  const handleConfirmCancel = (e) => {
    e.preventDefault();
    setShowCancel(false);
    setShowModal(false);
  };

  return (
    <>
      {/* Skeleton Article */}
      {loading2Status ? (
        <div className="content">
          <div className="announcement-skeleton-container">
            <div>
              {[1, 2, 3, 4].map((a, i) => (
                <SkeletonArticle key={i} />
              ))}
              <div className="notification-time"></div>
            </div>
          </div>
        </div>
      ) : (
        // Info Content
        <div className="div-block-4">
          <h3>Profile Information</h3>
          <div className="input-wrapper">
            <label className="form-label">School Name</label>
            <div>{company_name}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Website</label>
            <div>{website}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Email</label>
            <div>{email}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Contact Number</label>
            <div>{`${info?.tel.substring(0, 4)} ${info?.tel.substring(
              4
            )}`}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Whatsapp Number</label>
            <div>{`${info?.whatsapp.substring(0, 4)} ${info?.whatsapp.substring(
              4
            )}`}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Address</label>
            <div>
              {subAddress}, {area_name}, {district_name}, {region}
            </div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">Office Hours</label>
            <div>{officeHours}</div>
            <br />
          </div>
          <div className="input-wrapper">
            <label className="form-label">School Description</label>
            <div>{description}</div>
            <br />
          </div>
          <button onClick={handleShowModal} className="button w-button">
            Update Info
          </button>

          {/* Image */}
          {/* Temp inline styling */}
          <div>
            <div className="d-flex justify-content-between school-preview">
              <div className="input-wrapper preview">
                <label className="form-label">Schoole Page Preview</label>

                <img
                  src={banner || placeholder_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholder_image;
                  }}
                  loading="lazy"
                  alt=""
                  className="banner"
                />

                <img
                  src={logo || placeholder_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholder_image;
                  }}
                  loading="lazy"
                  alt=""
                  className="school-icon"
                />
                <Link
                  id="update-img-button"
                  className="button w-button"
                  to="/profile/image_upload"
                >
                  Update Images
                </Link>
              </div>
            </div>
          </div>

          {/* Modal For update info */}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop="static"
            keyboard={false}
            className="d-flex justify-content-center"
          >
            <Modal.Title>
              <h3 className="text-center">Edit Profile Information</h3>
            </Modal.Title>
            <Modal.Body>
              <div className="div-block-4">
                <div className="w-form">
                  <form
                    id="wf-form-Campus-Profile"
                    name="wf-form-Campus-Profile"
                    data-name="Campus Profile"
                    method="get"
                    className="form-3"
                  >
                    <div className="input-wrapper">
                      <label className="form-label">School Name</label>
                      <input
                        value={newSchoolName}
                        onChange={(e) => setSchoolName(e.target.value)}
                        type="text"
                        className="w-input"
                        id="School-Name"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label className="form-label">Website</label>
                      <input
                        value={newWebsite}
                        onChange={(e) => setWebsite(e.target.value)}
                        type="text"
                        className="w-input"
                        id="Website"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label className="form-label">Email</label>
                      <input
                        value={newEmail}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        className="w-input"
                        id="Email"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label className="form-label">Contact Number</label>
                      <input
                        value={newTel}
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                        type="text"
                        className="w-input"
                        id="Tel"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label className="form-label">Whatsapp Number</label>
                      <input
                        value={newWhatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                        type="text"
                        className="w-input"
                        id="Whatsapp"
                      />
                    </div>
                    {/* Location */}

                    <div className="input-wrapper form-group">
                      <label className="form-label">Region</label>
                      <select
                        className="form-select w-input"
                        placeholder="region"
                        value={newRegion}
                        onChange={(e) => {
                          setRegion(e.target.value);
                          setDistrict("");
                          setArea("");
                        }}
                      >
                        <option value="">Select Region</option>
                        <option>New Territories</option>
                        <option>Kowloon</option>
                        <option>Hong Kong Island</option>
                      </select>
                    </div>

                    <div className="input-wrapper form-group">
                      <label className="form-label">District</label>
                      <select
                        className="form-select w-input"
                        placeholder="district"
                        value={newDistrict}
                        onChange={(e) => {
                          setDistrict(e.target.value);
                          setArea("");
                        }}
                      >
                        <option value="">Select District</option>
                        {locationInfo.district
                          .filter((dist) => dist.region === newRegion)
                          .map((dist, i) => (
                            <option key={i}>{dist.district_name}</option>
                          ))}
                      </select>
                    </div>

                    <div className="input-wrapper form-group">
                      <label className="form-label">Area</label>
                      <select
                        className="form-select w-input"
                        placeholder="area"
                        value={newArea}
                        onChange={(e) => {
                          setArea(e.target.value);
                        }}
                      >
                        <option value="">Select Area</option>
                        {locationInfo.area.filter(
                          (a) =>
                            a.district_id ===
                            locationInfo.district.filter(
                              (dist) => dist.district_name === newDistrict
                            )[0]?.id
                        ).length > 0 ? (
                          locationInfo.area
                            .filter(
                              (a) =>
                                a.district_id ===
                                locationInfo.district.filter(
                                  (dist) => dist.district_name === newDistrict
                                )[0]?.id
                            )
                            .map((a, i) => {
                              return <option key={i}>{a.area_name}</option>;
                            })
                        ) : (
                          <option disabled> No Area </option>
                        )}
                      </select>
                    </div>

                    <div className="input-wrapper">
                      <label className="form-label">Sub-address</label>
                      <input
                        value={newSubAddress}
                        onChange={(e) => setSubAddress(e.target.value)}
                        type="text"
                        className="w-input"
                        id="SubAddress"
                      />
                    </div>

                    <div className="input-wrapper">
                      <label className="form-label">Office Hours</label>
                      <input
                        value={newOfficeHours}
                        onChange={(e) => setOfficeHours(e.target.value)}
                        type="text"
                        className="w-input"
                        id="OfficeHours"
                      />
                    </div>
                    <div className="input-wrapper">
                      <label className="form-label">School Description</label>
                      <input
                        value={newDescription}
                        onChange={(e) => setDescription(e.target.value)}
                        type="text"
                        className="w-input"
                        id="Description"
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={handleCancel}
                        className="button w-button"
                      >
                        Cancel
                      </button>
                      <input
                        onClick={handleEdit}
                        type="submit"
                        value="Update"
                        data-wait="Please wait..."
                        className="button w-button"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Confirm Cancel Model */}
          <Modal
            show={showCancel}
            onHide={() => {
              setShowCancel(false);
            }}
            animation={false}
            centered="true"
            backdrop="static"
            keyboard={false}
            className="d-flex justify-content-center"
          >
            <Modal.Body>
              <h4>Changes unsaved. Are you sure to cancel edit?</h4>
              <div className="d-flex justify-content-between">
                <button
                  onClick={() => {
                    setShowModal(true);
                    setShowCancel(false);
                  }}
                  className="button w-button"
                >
                  No
                </button>
                <button
                  onClick={handleConfirmCancel}
                  className="button w-button"
                >
                  Yes
                </button>
              </div>
            </Modal.Body>
          </Modal>

          {/* Saved Modal */}
          <Modal
            show={showSaved}
            onHide={() => setShowSaved(false)}
            centered="true"
            backdrop="true"
            keyboard={false}
            className="d-flex justify-content-center"
          >
            <Modal.Body>
              <img src={success} loading="lazy" width="30" height="30" />
              <span>Updated Sucessfully</span>
            </Modal.Body>
          </Modal>

          {/* Error Modal */}
          <Modal
            show={showError}
            onHide={() => setShowError(false)}
            centered="true"
            backdrop="true"
            keyboard={false}
            className="d-flex justify-content-center"
          >
            <Modal.Body>
              <span>Update error: please select a valid area location</span>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}
