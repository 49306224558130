import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { familyGetSuccessThunk } from "../../Redux/familyInfo/actions";
import {
  Routes,
  BrowserRouter as Router,
  Link,
  Route,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";

import FamilyNav from "./FamilyNav";

export default function Profile() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(familyGetSuccessThunk());
  }, []);
  return (
    <div>
      <FamilyNav />
      <Outlet />
    </div>
  );
}
