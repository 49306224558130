import React from "react";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import { useState, useEffect } from "react";
import { familyGetSuccessThunk } from "../../../Redux/familyInfo/actions";
import { useDispatch, useSelector } from "react-redux";

export default function SearchProfile() {
  // Get all the family info
  const infoAllFamilyArray = useSelector((state) => state.familyInfoStore);
  const [allFamilyArray, setallFamilyArray] = useState(
    infoAllFamilyArray.family
  );

  // SearchBar State
  const [search, setSearch] = useState("");

  // Handle Search
  const onSearchBarChange = (search) => {
    setSearch(search);
  };

  // // Take the key words and return filtered list of links
  const filteredFamily = (search) => {
    const lowerSearch = search.toLowerCase();
    const filteredArray = allFamilyArray.filter((family) => {
      if (family.parent[0].length > 0 && family.student[0].length > 0) {
        return (
          // Search from family info
          family.family_email.toLowerCase().includes(lowerSearch) ||
          // Search from parent info
          family.parent[0]
            .map((parent) => {
              return (
                parent.firstName
                  .toLowerCase()
                  .concat(" ", parent.lastName.toLowerCase())
                  .includes(lowerSearch) ||
                parent.firstName.toLowerCase().includes(lowerSearch) ||
                parent.lastName.toLowerCase().includes(lowerSearch) ||
                parent.tel.toLowerCase().includes(lowerSearch)
              );
            })
            .indexOf(true) > -1 ||
          // Search from student info
          family.student[0]
            .map((student) => {
              return (
                student.firstName
                  .toLowerCase()
                  .concat(" ", student.lastName.toLowerCase())
                  .includes(lowerSearch) ||
                student.firstName.toLowerCase().includes(lowerSearch) ||
                student.lastName.toLowerCase().includes(lowerSearch) ||
                student.hkid.toLowerCase().includes(lowerSearch)
              );
            })
            .indexOf(true) > -1
        );
      } else if (
        family.parent[0].length > 0 &&
        !(family.student[0].length > 0)
      ) {
        return (
          // Search from family info
          family.family_email.toLowerCase().includes(lowerSearch) ||
          // Search from parent info
          family.parent[0]
            .map((parent) => {
              return (
                parent.firstName
                  .toLowerCase()
                  .concat(" ", parent.lastName.toLowerCase())
                  .includes(lowerSearch) ||
                parent.firstName.toLowerCase().includes(lowerSearch) ||
                parent.lastName.toLowerCase().includes(lowerSearch) ||
                parent.tel.toLowerCase().includes(lowerSearch)
              );
            })
            .indexOf(true) > -1
        );
      } else {
        return (
          // Search from family info
          family.family_email.toLowerCase().includes(lowerSearch)
        );
      }
    });
    return filteredArray;
  };

  return (
    <div className="div-block-4">
      <SearchBar onSearchChangeProp={onSearchBarChange} />

      <h4 className="family-search-result">Family Search Result</h4>

      <SearchResults family={filteredFamily(search)} />
    </div>
  );
}
