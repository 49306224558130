import axios from "axios";

export const LOCATION_SUCCESS_ACTION = 'LOCATION_SUCCESS_ACTION';
export const LOCATION_FAILURE_ACTION = 'LOCATION_FAILURE_ACTION';

//Thunk action creator
export const locationSuccess = (updated, message)=>{
    return({
        type: LOCATION_SUCCESS_ACTION,
        updated: updated,
        message: message
    })
}

export const locationFailure = (message)=>{
    return({
        type: LOCATION_FAILURE_ACTION,
        message: message
    })
}

//Thunk actions

//Put campus location
export const locationPutCampusThunk = (coords) =>{
    let token = localStorage.getItem("token");
    return (dispatch)=>{
        if(!coords || !coords.lat || !coords.lng){
            dispatch(locationFailure(`Please select a valid pinpoint.`))
            return
        } 
        axios.put(`${process.env.REACT_APP_API_SERVER}/api/location/`, coords, {headers: {Authorization: `Bearer ${token}`}}).then((res)=>{
            console.log(res.data)
            dispatch(locationSuccess(res.data, 'Updated successfully'))
        })
        .catch((err)=>{
            console.log(err)
            dispatch(locationFailure(`post request error: ${err}`))
        })
    }
}

