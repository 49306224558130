import React from "react";
import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";
import { useState, useEffect } from "react";
import { familyGetSuccessThunk } from "../../../Redux/familyInfo/actions";
import { useDispatch, useSelector } from "react-redux";

export default function SearchProfile() {
  // Get all the family info
  const infoAllCourseArray = useSelector((state) => state.courseInfoStore);
  const [allCourseArray, setallCourseArray] = useState(
    infoAllCourseArray.course
  );

  // SearchBar State
  const [search, setSearch] = useState("");

  // Handle Search
  const onSearchBarChange = (search) => {
    setSearch(search);
  };

  // // Take the key words and return filtered list of links
  const filteredCourse = (search) => {
    const lowerSearch = search.toLowerCase();
    const filteredArray = allCourseArray.filter((course) => {
      return (
        course.title.toLowerCase().includes(lowerSearch) ||
        course.category.toLowerCase().includes(lowerSearch)
      );
      if (
        course.lesson[0].length > 0 &&
        course.lesson[0][0].attendance[0].length > 0
      ) {
        return (
          // Search from course info
          course.title.toLowerCase().includes(lowerSearch) ||
          course.category.toLowerCase().includes(lowerSearch) ||
          // Search from attendance info
          course.lesson[0]
            .map((lesson) => {
              return lesson.attendance[0].map((student) => {
                return (
                  student.firstName.toLowerCase().includes(lowerSearch) ||
                  student.lastName.toLowerCase().includes(lowerSearch) ||
                  student.firstName
                    .toLowerCase()
                    .concat(" ", student.lastName.toLowerCase())
                    .includes(lowerSearch) ||
                  student.hkid.toLowerCase().includes(lowerSearch)
                );
              });
            })
            .indexOf(true) > -1
        );
      } else if (
        course.lesson[0].length > 0 &&
        !(course.lesson[0][0].attendance[0].length > 0)
      ) {
        return (
          // Search from course info
          course.title.toLowerCase().includes(lowerSearch) ||
          course.category.toLowerCase().includes(lowerSearch)
        );
      }
    });
    return filteredArray;
  };

  return (
    <div className="div-block-4">
      <SearchBar onSearchChangeProp={onSearchBarChange} />

      <h4 className="family-search-result">Course List</h4>

      <SearchResults course={filteredCourse(search)} />
    </div>
  );
}
