import axios from "axios";
import { loading2ToggleAction } from "../loading2/actions";

export const ANNOUNCEMENT_SUCCESS_ACTION = "ANNOUNCEMENT_SUCCESS_ACTION";
export const ANNOUNCEMENT_ALL_SUCCESS_ACTION =
  "ANNOUNCEMENT_ALL_SUCCESS_ACTION";

export const ANNOUNCEMENT_FAILURE_ACTION = "ANNOUNCEMENT_FAILURE_ACTION";

//Thunk action creator
export const announcementSuccess = (announcement) => {
  return {
    type: ANNOUNCEMENT_SUCCESS_ACTION,
    announcement: announcement,
  };
};

export const announcementFailure = (message) => {
  return {
    type: ANNOUNCEMENT_FAILURE_ACTION,
    message: message,
  };
};

//Thunk actions

//Get Announcement
export const announcementGetThunk = () => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .delete(`${process.env.REACT_APP_API_SERVER}/api/announcement`, {headers: {Authorization: `Bearer ${token}`}})
      .then((res) => {
        console.log(res.data);
        dispatch(announcementSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(announcementFailure(`error: ${err}`));
      });
  };
};

//Post Announcement
export const announcementPostThunk = (announcement) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/announcement/`, {announcement}, {headers: {Authorization: `Bearer ${token}`}})
      .then((res) => {
        console.log(res.data);
        dispatch(announcementSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(announcementFailure(`error: ${err}`));
      });
  };
};

//Toggle isActive boolean Announcement
export const announcementActivateThunk = (id) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_SERVER}/api/announcement/activate/${id}`, null,  {headers: {Authorization: `Bearer ${token}`}})
      .then((res) => {
        console.log(res.data);
        dispatch(announcementSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(announcementFailure(`error: ${err}`));
      });
  };
};
