import React, { useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { imageUploadThunk, imageSuccess } from '../../Redux/imageS3/actions';
import { infoGetCampusThunk } from "../../Redux/info/actions"


import placeholder_image from "../../assets/images/placeholder-image.jpeg";
import placeholder_image_500 from "../../assets/images/placeholder-image-p-500.jpeg";
import success from "../../assets/images/success.png";


export default function ImageUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const message = useSelector((state) => state.imageStore.message);
    const info = useSelector((state) => state.infoStore.info)[0]

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(infoGetCampusThunk())
        dispatch(imageSuccess(''))
    }, []);

    const logoRef = useRef();
    const bannerRef = useRef();

    return (
        <div className="d-flex flex-column align-items-center">
            <div>
                <h3>School Logo</h3>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    dispatch(imageUploadThunk(selectedFile, '/api/info/campus', 'logo')) // api endpoint, item
                    setSelectedFile(null)
                    logoRef.current.value = '';
                    bannerRef.current.value = '';
                    setShowModal(true)
                    setTimeout(() => {
                        setShowModal(false);
                    }, 2000);
                }}>
                    <input style={{display:'none'}} type="file" accept="image/*" onChange={(e) => {
                        setSelectedFile(e.target.files[0])
                    }} ref={logoRef}/>

                    <button onClick={(e)=>{
                        e.preventDefault();
                        logoRef.current.click()
                    }} className='button w-button'>Select Image</button>

                    <span>{logoRef.current && logoRef.current.value.length >0 ? logoRef.current.value.replace(`C:\\fakepath\\`, '') : 'No file chosen'}</span> 
                    <input type='submit' className='button w-button'/>
                </form>
            </div>

            <div>
                <h3>School Banner</h3>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    dispatch(imageUploadThunk(selectedFile, '/api/info/campus', 'banner')) // api endpoint, item
                    setSelectedFile(null)
                    logoRef.current.value = '';
                    bannerRef.current.value = '';
                    setShowModal(true)
                    setTimeout(() => {
                        setShowModal(false);
                    }, 2000);
                }}>
                    <input style={{display:'none'}} type="file" accept="image/*" onChange={(e) => {
                        setSelectedFile(e.target.files[0])
                    }} ref={bannerRef}/>

                    <button onClick={(e)=>{
                        e.preventDefault();
                        bannerRef.current.click()
                    }} className='button w-button'>Select Image</button>

                    <span>{bannerRef.current && bannerRef.current.value.length >0 ? bannerRef.current.value.replace(`C:\\fakepath\\`, '') : 'No file chosen'}</span> 
                    <input type='submit' className='button w-button'/>
                </form>
            </div>

            {/* On click submit Modal */}
            <Modal show={showModal} onHide={()=>setShowModal(false)} centered="true" backdrop="true" keyboard={false} className='d-flex justify-content-center'>
                <Modal.Body>
                {message === 'Updated successfully' ? <>
                    <img src={success} loading="lazy" width="30" height="30" />
                    <span>{message}</span>
                </> : <>
                    <span>{message}</span>
                </>
                }
                </Modal.Body>
            </Modal>
        </div>
    );
};
