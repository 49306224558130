import axios from "axios";
export const SCORE_GET_REQUEST = "SCORE_GET_REQUEST";
export const SCORE_GET_SUCCESS = "SCORE_GET_SUCCESS";
export const SCORE_GET_FAILURE = "SCORE_GET_FAILURE";

//Thunk action creators
//Get all score thunk action creators
export const scoreGetRequest = () => {
  return {
    type: SCORE_GET_REQUEST,
  };
};
export const scoreGetSuccess = (data) => {
  return {
    type: SCORE_GET_SUCCESS,
    listings: data.listings,
    reviews: data.reviews,
  };
};
// export const scoreGetSuccess = (data) => {
//   return {
//     type: SCORE_GET_SUCCESS,
//     listings: data.listings,
//     reviews: data.reviews,
//   };
// };
export const scoreGetFailure = (message) => {
  return {
    type: SCORE_GET_FAILURE,
    message: message,
  };
};

// export const scoreGetSuccessThunk = (campusId) => {
//   return (dispatch) => {
//     axios
//       .get(`${process.env.REACT_APP_API_SERVER}/api/review/all/${campusId}`)
//       .then((res) => {
//         console.log(res.data);
//         dispatch(scoreGetSuccess(res.data));
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch(scoreGetFailure(`post request error: ${err}`));
//       });
//   };
// };

//Get all score review thunk actions
export const scoreGetSuccessThunk = () => {
  return (dispatch) => {
    const data = {};
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/allCampus`)
      .then((res) => {
        data.listings = res.data;
        const reviews = {};
        for (let i = 0; i < data.listings.length; i++) {
          axios
            .get(
              `${process.env.REACT_APP_API_SERVER}/api/review/all/${data.listings[i].id}`
            )
            .then((res) => {
              if(!res.data || res.data.length === 0){
                reviews[`${data.listings[i].id}`] = []
              } else {
                reviews[`${data.listings[i].id}`] = res.data;
              }
            });
        }
        data.reviews = reviews;
        console.log('data',data);
        dispatch(scoreGetSuccess(data))
      })
      // .then(() => dispatch(scoreGetSuccess(data)))
      .catch((err) => {
        dispatch(scoreGetFailure(`post request error: ${err}`));
      });
  };
};

//Thunk actions
//Get all score info thunk actions
// export const scoreGetSuccessThunk = () => {
//   return (dispatch) => {
//     axios
//       .get(`${process.env.REACT_APP_API_SERVER}/api/info/allCampus`)
//       .then((res) => {
//         dispatch(scoreGetSuccess(res.data));
//       })
//       .catch((err) => {
//         dispatch(scoreGetFailure(`error: ${err}`));
//       });
//   };
// };

//Get all score review thunk actions
// export const reviewGetAllThunk = (campusId) => {
//   return (dispatch) => {
//     let token = localStorage.getItem("token");
//     axios
//       .get(`${process.env.REACT_APP_API_SERVER}/api/review/all/${campusId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((res) => {
//         console.log(res.data);
//         dispatch(reviewSuccess(res.data));
//       })
//       .catch((err) => {
//         console.log(err);
//         dispatch(reviewFailure(`post request error: ${err}`));
//       });
//   };
// };
