import {
    INFO_SUCCESS_ACTION,
    INFO_ALL_SUCCESS_ACTION,
    INFO_FAILURE_ACTION
} from './actions'

const initialState = {
    info: [],
    infoAll: [],
    message: ''
};

export const infoReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case INFO_SUCCESS_ACTION:
            return {...state, info: action.info};
          case INFO_ALL_SUCCESS_ACTION:
            return {...state, infoAll: action.infoAll};
          case INFO_FAILURE_ACTION:
            return {...state, message: action.message};
          default:
              return state; 
      }
};