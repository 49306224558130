import React from "react";
import { useState, useEffect } from "react";
import DetailedInfoModal from "./DetailedInfoModal";

export default function SearchResults(props) {
  // Handle student age
  const handleAge = (dob) => {
    let timeDiff = new Date() - new Date(dob);
    let years = Math.floor(timeDiff / 1000 / 3600 / 24 / 365);
    let months = Math.floor(timeDiff / 1000 / 3600 / 30);
    if (years > 0) {
      return `${years}`;
    } else {
      return `${months}m`;
    }
  };

  return (
    <>
      {props.family && props.family.length > 0 ? (
        props.family.map((family, i) => (
          <div className="family-card" key={i}>
            <div className="columns-3 w-row">
              <div className="w-col w-col-4">
                <div className="family-id">Family ID #{family.id}</div>
                <h5 className="family-email">{family.family_email}</h5>
                {family.parent[0] && family.parent[0].length > 0 ? (
                  <div>{family.parent[0][0].tel}</div>
                ) : (
                  <h5 className="makeitcentre">No phone number found</h5>
                )}
              </div>
              <div className="w-col w-col-3">
                <h6>Parent account(s)</h6>
                {/* Parent start */}
                {family.parent[0] && family.parent[0].length > 0 ? (
                  <ul role="list">
                    {family.parent[0].map((parent, i) => (
                      <li key={i}>
                        {parent.relationship}: {parent.firstName}{" "}
                        {parent.lastName}
                      </li>
                    ))}{" "}
                  </ul>
                ) : (
                  <h5 className="makeitcentre">No parent found</h5>
                )}
                {/* Parent end */}
              </div>
              <div className="w-col w-col-3">
                <h6>Student account(s)</h6>
                {/* Student Start */}
                {family.student[0] && family.student[0].length > 0 ? (
                  <ul role="list">
                    {family.student[0].map((student, i) => (
                      <li key={i}>
                        {student.firstName} {student.lastName} (
                        {handleAge(student.dob)})
                      </li>
                    ))}{" "}
                  </ul>
                ) : (
                  <h5 className="makeitcentre">No student found</h5>
                )}
                {/* Student End */}
              </div>
              {/* <DetailedInfoModal family={family}></DetailedInfoModal> */}
            </div>
          </div>
        ))
      ) : (
        <>
          <h5 className="makeitcentre">No family found</h5>
        </>
      )}
    </>
  );
}
