import { SCORE_GET_SUCCESS, SCORE_GET_FAILURE } from "./actions";

const initialState = {
  listings: [],
  reviews: {},
  message: "",
};

export function scoreReducers(state = initialState, action) {
  switch (action.type) {
    case SCORE_GET_SUCCESS:
      return { listings: action.listings, reviews: action.reviews };
    case SCORE_GET_FAILURE:
      return { ...state, message: action.message };
    default:
      return state;
  }
}
