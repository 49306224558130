import {
    COURSE_SUCCESS_ACTION,
    COURSE_FAILURE_ACTION
} from './actions'

const initialState = {
    course: [],
    message: ''
};

export const courseReducers = (state = initialState, action) => {
    // Use switch to handle different actions
      switch (action.type) {
          case COURSE_SUCCESS_ACTION:
            return {course: action.course, message: ''};
          case COURSE_FAILURE_ACTION:
            return {course: [], message: action.message};
          default:
              return state; 
      }
};