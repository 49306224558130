import axios from "axios";
import { loadingToggleAction } from "../loading/actions";
import { loading2ToggleAction } from "../loading2/actions";

export const INFO_SUCCESS_ACTION = "INFO_SUCCESS_ACTION";
export const INFO_ALL_SUCCESS_ACTION = "INFO_ALL_SUCCESS_ACTION";
export const INFO_FAILURE_ACTION = "INFO_FAILURE_ACTION";

//Thunk action creator
export const infoSuccess = (info) => {
  return {
    type: INFO_SUCCESS_ACTION,
    info: info,
  };
};

export const infoAllSuccess = (infoAll) => {
  return {
    type: INFO_ALL_SUCCESS_ACTION,
    infoAll: infoAll,
  };
};

export const infoFailure = (message) => {
  return {
    type: INFO_FAILURE_ACTION,
    message: message,
  };
};

//Thunk actions

//Get All Campus
export const infoGetAllCampusThunk = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_SERVER}/api/info/allCampus`)
      .then((res) => {
        console.log(res.data);
        dispatch(infoAllSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loadingToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(infoFailure(`error: ${err}`));
      });
  };
};

//Get Campus by id
export const infoGetCampusByIdThunk = (campusId) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/info/campusById/${campusId}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.length == 0) {
          console.log("no campus");
          window.location = `/error`;
        }
        dispatch(infoSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(infoFailure(`error: ${err}`));
      });
  };
};

//Get Campus by login user
export const infoGetCampusThunk = () => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_SERVER}/api/info/campus`, {headers: {Authorization: `Bearer ${token}`}})
      .then((res) => {
        console.log(res.data);
        if (res.data.length == 0) {
          console.log("no campus");
          window.location = `/error`;
        }
        dispatch(infoSuccess(res.data));
      })
      .then(() =>
        setTimeout(() => {
          dispatch(loading2ToggleAction(false));
        }, 500)
      )
      .catch((err) => {
        console.log(err);
        dispatch(infoFailure(`error: ${err}`));
      });
  };
};

//Put Campus info
export const infoPutCampusThunk = (info) => {
  let token = localStorage.getItem("token");
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_API_SERVER}/api/info/campus`, {info}, {headers: {Authorization: `Bearer ${token}`}}
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.length == 0) {
          console.log("no campus");
          window.location = `/error`;
        }
        dispatch(infoSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(infoFailure(`error: ${err}`));
      });
  };
};
