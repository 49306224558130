import GoogleLogin from 'react-google-login';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginCampusThunk } from "../Redux/auth/actions";
import { useNavigate } from "react-router-dom";



export const LoginFormCampus = () =>{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const authRole = useSelector((state) => state.authStore.authRole);
    const message = useSelector((state) => state.authStore.message);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = () => {
        console.log('normal login campus')
        dispatch(loginCampusThunk(email, password));
      };
    
    // When authRole is changed,
    useEffect(() => {
        if (authRole == 'campus') {
            navigate("/profile");
        }
    }, [authRole]);

    return (
    //Campus login input fields
    //===============================
        <>
        <div className="form-block form-container container d-flex flex-column align-items-center">
            <div className="element-container text-center form-heading"><h3>Campus Login</h3></div>
            <div className="element-container form-label"> Email:
                <input className="email-input w-input" onChange={(e) => setEmail(e.currentTarget.value)} type="text" value={email}/>
            </div>     
            <div className="element-container form-label"> Password: 
                <input className="pw-input w-input" onChange={(e) => setPassword(e.currentTarget.value)} type="password" value={password}/>
            </div>

            <div className="login-btn-container d-flex justify-content-around align-items-center">
            <button className="login-btn button login w-button" onClick={login}>LOGIN</button>
            </div>
            {/* {/* //Displaying message, if not logged in */}
            {authRole != '' ? null : <h5 className="login-fail-msg text-center">{message}</h5>}
        </div>
        </>
    )
}
