import React from 'react'
import { Modal } from "react-bootstrap";
import { Routes, BrowserRouter as Router, Link, Route, Navigate, Outlet, useParams, useNavigate, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import SkeletonArticle from "../../skeletons/SkeletonArticle";
import { loading2ToggleAction } from "../../Redux/loading2/actions";
import { announcementGetThunk, announcementPostThunk, announcementActivateThunk } from "../../Redux/announcement/actions";


export default function Announcement() {
const dispatch = useDispatch();
useEffect(() => {
    dispatch(loading2ToggleAction(true))
    dispatch(announcementGetThunk());
}, []);

const [title, setTitle] = useState("");
const [content, setContent] = useState("");
const [showModal, setShowModal] = useState(null);

const announcementArray = useSelector((state) => state.announcementStore.announcement);
const loading2Status = useSelector((state) => state.loading2Store.showLoading);

const handleAdd = (e) => {
    e.preventDefault();
    console.log(title, content)
    dispatch(announcementPostThunk({title, content}))
    setTitle('')
    setContent('')
}

const handleTime = (updatedAt) => {
    let update = new Date(updatedAt).toString().split(/\s/);
    return `Published on ${update[2]} ${update[1]} ${update[3]} ${update[4].slice(0, 5)}`;
  };

  return (
    <>
{/* Skeleton Article */}
        {loading2Status ? (
        <div className="content">
          <div className="announcement-skeleton-container">
            <div>
                {[1,2,3,4].map((a,i)=>(
                    <SkeletonArticle key={i}/>
                ))}
              <div className="notification-time"></div>
            </div>
          </div>
          </div>
      ) : (
// Announcement Content
    <div className="content">
      <div className="page-nav">
        <div className="nav-item">Announcements</div>
      </div>
      <div className="div-block-4">
        <div className="form-block-3 w-form">
            <form id="wf-form-Annonucement-Form" name="wf-form-Annonucement-Form" data-name="Annonucement Form" method="get">
                <label htmlFor="Announcement-Title">Add new announcement</label>
                <input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" className="w-input" maxLength="256" name="Announcement-Title" data-name="Announcement Title" placeholder="Title" id="Announcement-Title" required="" />
                <textarea value={content} onChange={(e)=>setContent(e.target.value)} id="Announcement-details" name="Announcement-details" maxLength="5000" placeholder="Announcement details" data-name="field" className="w-input" />
                <input onClick={handleAdd} type="submit" value="Add" data-wait="Please wait..." className="button w-button" />
            </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>

        {/* Activate List */}
        <div className="announcement-container">
            <h3>Activate Announcements</h3>
            {announcementArray && announcementArray.length > 0 && announcementArray.some((a)=>a.isActive) ? announcementArray.map((a)=>{
                if(a.isActive) return (
                <div key={a.id}>
                    <h5 className="title">{a.title}</h5>
                    <p className="content-2">{a.content}</p>
                    <div className="annoucement-wrapper">
                        <div className="notification-time">{handleTime(a.updated_at)}</div>
                        <button onClick={()=>setShowModal(a.id)} className="delete">DEACTIVATE</button>
                        <Modal show={showModal===a.id} onHide={()=>setShowModal(null)} backdrop="static" centered='true' keyboard={false} className='d-flex justify-content-center'>
                            <Modal.Body>
                                <p className='text-center'>Are you sure to deactivate this announcement?</p>
                                <h5 className='text-center'>{a.title}</h5>
                                <div className='d-flex justify-content-between'>
                                    <button onClick={()=>setShowModal(null)}> Cancel </button>
                                    <button className="delete" onClick={()=>{
                                        dispatch(announcementActivateThunk(a.id))
                                        setShowModal(null)
                                    }}> Confirm </button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            )
            }) : 'No Announcements'}
        </div>

        {/* Deativate List */}
        {/* Inline styling TBC */}
        <div style={{backgroundColor: '#ccc'}} className="announcement-container">
            <h3>Deactivated Announcements</h3>
            {announcementArray && announcementArray.length > 0 && announcementArray.some((a)=>!a.isActive) ? announcementArray.map((a)=>{
                if(!a.isActive) return (
                <div key={a.id}>
                    <h5 className="title">{a.title}</h5>
                    <p className="content-2">{a.content}</p>
                    <div className="annoucement-wrapper">
                        <div className="notification-time">{handleTime(a.updated_at)}</div>
                        <button onClick={()=>dispatch(announcementActivateThunk(a.id))} className="delete">RE-ACTIVATE</button>
                    </div>
                </div>
            )
            }) : 'No Announcements'}
        </div>
      </div>
    </div>
    )}
  </>)
}
