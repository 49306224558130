import React, {useState, useRef, useCallback, useEffect} from 'react'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, useLoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

import { locationPutCampusThunk, locationFailure } from '../../Redux/location/actions';
import { infoGetCampusThunk } from "../../Redux/info/actions"

import { clear } from '@testing-library/user-event/dist/clear';
import success from "../../assets/images/success.png";


const libraries = ['places']

const containerStyle = {
  width: '700px',
  height: '700px'
};


const options={
  //Enable different default
  //Custom Style for map
  disableDefaultUI: true,
  zoomControl: true,
}


export default function Map() {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.locationStore.message);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  })
  const [marker, setMarker] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const info = useSelector((state) => state.infoStore.info)[0]
  let lat = Number(info?.lat)
  let lng = Number(info?.lng)

  
  useEffect(() => {
    dispatch(locationFailure(''))
    dispatch(infoGetCampusThunk())
    }, []);


  const handleClick = (e) =>{
    setClicked(true)
    setMarker([{
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    }])
  }

  const handleSubmit = () =>{
    dispatch(locationPutCampusThunk(marker[0]))
    setShowModal(true)
    setTimeout(() => {
      setShowModal(false);
    }, 2000);
  }

  const mapRef = useRef();
  const onMapLoad = useCallback((map)=> {
    mapRef.current = map
  }, [])

  const panTo = useCallback(({lat, lng})=>{
    mapRef.current.panTo({lat, lng});
    mapRef.current.setZoom(16);
  },[])

  


  if(loadError) return 'Error loading maps'
  if(!isLoaded) return 'Loading maps'

  return isLoaded ? (
    <>
    <div className="d-flex flex-column align-items-center">
    <h3>Map Location</h3>
    <p>Please pinpoint the location of your campus on the map</p>
      <Search panTo={panTo} />
    <div className='d-flex justify-content-between'>
      <Locate panTo={panTo} />
      <button onClick={handleSubmit} className="button w-button" >Submit Pinpoint</button>
    </div>

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat: marker[0]?.lat || lat || 22.3703963, lng: marker[0]?.lng || lng || 114.110870}}
        zoom={15}
        options={options}
        onClick={handleClick}
        onLoad={onMapLoad}
      >
        {clicked? null: <Marker position={{lat: lat, lng: lng}} />}
        {marker && marker[0] ? <Marker position={{lat: marker[0]?.lat, lng: marker[0]?.lng}}/> : null}
    
      </GoogleMap>
    </div>
      {/* On click submit Modal */}
      <Modal show={showModal} onHide={()=>setShowModal(false)} centered="true" backdrop="true" keyboard={false} className='d-flex justify-content-center'>
            <Modal.Body>
              {message === 'Updated successfully' ? <>
                <img src={success} loading="lazy" width="30" height="30" />
                <span>{message}</span>
              </> : <>
                <span>{message}</span>
              </>
              }
            
            </Modal.Body>
      </Modal>
    </>
  ) : <></>
}

function Locate({panTo}) {
  return (
    <button onClick={()=>{
      navigator.geolocation.getCurrentPosition((position)=>{
        panTo({lat: position.coords.latitude, lng: position.coords.longitude})
      }, ()=>null, options);
    }} className="button w-button">Locate Me</button>
  )

}


function Search({panTo}) {
  const {ready, value, suggestions:{status, data}, setValue, clearSuggestions, } = usePlacesAutocomplete({
    requestOptions:{
      location: {
        lat: ()=>22.3703963,
        lng: ()=>114.110870
      },
      radius: 100*1000, //100km convert to meters
    }
  })

  const updated = useSelector((state) => state.locationStore.updated);
  useEffect(() => {
  setValue('')
  }, [updated]);

  return (
    <Combobox onSelect={async (address)=> {
      setValue(address, false)
      clearSuggestions()

      try {
        const results = await getGeocode({address})
        const { lat, lng } = await getLatLng(results[0])
        panTo({ lat, lng })
      } catch(error){
        console.log('error: ',error)
      }
    }}>

    {/* Temporary inline styling */}
    {/* Temporary inline styling */}
      <ComboboxInput className="w-input" style={{width: '400px'}} value={value} onChange={(e)=> setValue(e.target.value)} disabled={!ready} placeholder="Please enter an address"/>
      <ComboboxPopover>
        <ComboboxList>
        {status === 'OK' && data.map(({description},i)=> (
          <ComboboxOption key={i} value={description} />
        ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}


