import React from 'react'
import { Routes, BrowserRouter as Router, Link, Route, Navigate } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="page-nav">
        <Link to="/profile/info" className="nav-item">Profile Information</Link>
        <Link to="/profile/map" className="nav-item">Map Location</Link>
        <Link to="/profile/image_upload" className="nav-item">Update Images</Link>
    </div>
  )
}
