import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  imageUploadCourseThunk,
  imageSuccess,
} from "../../Redux/imageS3/actions";
import { infoGetCampusThunk } from "../../Redux/info/actions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import placeholder_image from "../../assets/images/placeholder-image.jpeg";
import placeholder_image_500 from "../../assets/images/placeholder-image-p-500.jpeg";
import success from "../../assets/images/success.png";

export default function ImageUpload() {
  const [coursePic, setCoursePic] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.imageStore.message);
  const info = useSelector((state) => state.infoStore.info)[0];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(infoGetCampusThunk());
    dispatch(imageSuccess(""));
  }, []);

  const courseLogoRef = useRef();

  const infoAllCourseArray = useSelector((state) => state.courseInfoStore);
  const [allCourseArray, setallCourseArray] = useState(
    infoAllCourseArray.course
  );

  //Handle Select On Change
  const [courseId, setCourseId] = useState(null);
  function onChangeCourse(e) {
    setCourseId(e.target.value);
    for (let i = 0; i < allCourseArray.length; i++) {
      if (allCourseArray[i].id - e.target.value == 0) {
        setCoursePic(allCourseArray[i].coursePic);
      }
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="selectCourseLogo">
        <h3>Course Image</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              imageUploadCourseThunk(
                selectedFile,
                `/api/course/courseId/${courseId}`,
                "coursePic"
              )
            ); // api endpoint, item
            setSelectedFile(null);
            courseLogoRef.current.value = "";
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
            }, 2000);
          }}
        >
          {/* Select child */}
          <div className="shareMethodContainer">
            <div className="selectMethod">
              <select
                id="field"
                name="course"
                data-name="Field"
                className="w-select"
                required
                onChange={onChangeCourse}
              >
                <option selected disabled hidden>
                  Select your course
                </option>
                {allCourseArray && allCourseArray.length > 0 ? (
                  allCourseArray.map((course, i) => (
                    <option value={course.id} key={i}>
                      {course.title}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>

          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              setSelectedFile(e.target.files[0]);
            }}
            ref={courseLogoRef}
          />
          <div className="imageButtonContainer">
            <button
              onClick={(e) => {
                e.preventDefault();
                courseLogoRef.current.click();
              }}
              className="button w-button"
              id="courseSelection"
            >
              Select Image
            </button>

            <span>
              {courseLogoRef.current && courseLogoRef.current.value.length > 0
                ? courseLogoRef.current.value.replace(`C:\\fakepath\\`, "")
                : "No file chosen"}
            </span>

            <input type="submit" className="button w-button" />
          </div>
          <div>
            <h6>Current Course Image</h6>
            <img
              src={coursePic || placeholder_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholder_image;
              }}
              loading="lazy"
              alt=""
              className="banner"
            />
          </div>
          <div className="imageButtonContainer">
            <button
              onClick={(e) => {
                dispatch(
                  imageUploadCourseThunk(
                    null,
                    `/api/course/courseId/${courseId}`,
                    "coursePic"
                  )
                ); // api endpoint, item
              }}
              className="button w-button"
              id="deleteImage"
            >
              Delete Image
            </button>
          </div>
        </form>
      </div>

      {/* On click submit Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered="true"
        backdrop="true"
        keyboard={false}
        className="d-flex justify-content-center"
      >
        <Modal.Body>
          {message === "Updated successfully" ? (
            <>
              <img src={success} loading="lazy" width="30" height="30" />
              <span>{message}</span>
            </>
          ) : (
            <>
              <span>{message}</span>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
